import * as msal from '@azure/msal-browser';
import useState from '@/use/useState';
// This file is part of the sample or msal

export default class AuthService {
    constructor() {
        const config = {
            auth: {
                // used by Azure. these are use to identify the application (client) authority is the instance followed by the tenantId
                clientId: '60e5e1a9-0b1f-40cf-9cf0-158589abd2c2',
                authority: 'https://login.microsoftonline.com/f3582444-5224-479a-8015-e9288bf1d9ad'
            },
            cache: {
                cacheLocation: "sessionStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
              }
        };


        this.msalInstance = new msal.PublicClientApplication(config);

        // scopes are used to define access. In Azure the backend have to scopes, one for users and one for admins
        this.request = {
            scopes: ["api://bfafd068-37aa-420a-a258-34736f5d7d60/application.access", "api://bfafd068-37aa-420a-a258-34736f5d7d60/application.settings"]
        };

        this.accountId = '';
        this.idToken = '';
    }

    async login() {
        try {
            this.idToken = await this.msalInstance.loginPopup(this.request);
            const loggedInAccountName = this.idToken.idTokenClaims.preferred_username;

            this.accountId = loggedInAccountName;

            this.request.account = this.msalInstance.getAccountByUsername(loggedInAccountName);

            this.msalInstance.setActiveAccount(this.request.account)

            if(this.msalInstance.getAllAccounts().length > 0) {
                location.replace('/home')
            }

        } catch(error) {
            console.log(error);
        }
    }

    

    async getLoggedInUser() {
        try {
            const state = useState()
            
            if(this.msalInstance.getAllAccounts().length > 0) {
                state.value.loggedIn = true
                state.value.username = this.msalInstance.getActiveAccount().name

                const hasAdminAccess = [
                  "Anders Ebbesen Graae",
                  "Nicolai Wichmann",
                  "Natalie Sirich",
                  "Isabella Broch",
                ];

                if(hasAdminAccess.includes(this.msalInstance.getActiveAccount().name)){
                    state.value.adminAccess = true
                } else {
                    state.value.adminAccess = false
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    // gets an accesstoken, that we can use to make calls to the backend API
    async getAccessToken() {
        let tokenResponse = '';
        try {
            tokenResponse = await this.msalInstance.acquireTokenSilent(this.request);
            this.accessToken = tokenResponse.accessToken;
        }
        catch(error) {
            console.log(error);
            if (this.requiresInteraction(error)) {
                try {
                    tokenResponse = await this.msalInstance.acquireTokenPopup(this.request);
                    this.accessToken = tokenResponse.accessToken;
                }
                catch(error) {
                    console.log(error);
                }
            }
        }
    }
    async logout(){
        await this.msalInstance.logoutPopup();
    }
    requiresInteraction(errorCode) {
        // this is used in to errorhandle getAccesstoken above
        if (!errorCode || !errorCode.length) {
            return false;
        }
        return errorCode === "consent_required" ||
            errorCode === "interaction_required" ||
            errorCode === "login_required";
    }
}