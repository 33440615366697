<template>
  <form @submit.prevent="updateProjectShare()">
    <h3>{{ type_name }}</h3>
    <fieldset>
      <template v-for="role in roles" :key="role.role_id">
        <label :for="`project-${type_id}-${role.role_id}`" class="project-role-label">
          {{ role.role_name }}</label>
        <input type="number" min="0" :id="`project-${type_id}-${role.role_id}`" class="project-role" v-model="role.project_share" @blur="forceZero(role)" />
      </template>
      <label :for="`project-${type_id}-total`">Total</label>
      <input type="number" min="0" :id="`project-${type_id}-total`" class="project-role" v-model.number="total" readonly disabled />
    </fieldset>

    <button>Update</button>
  </form>
</template>

<script>
import { ref, watchEffect, reactive, computed, watch } from 'vue'

import useAPI from '@/use/useAPI'
const { putData, deleteData, postData } = useAPI();

export default {
  name: 'Project Share',
  props: ['data'],

  setup(props) {
    const roles = ref(props.data.roles);
    const type_id = ref(props.data.type_id);
    const type_name = ref(props.data.type_name);

    watchEffect(() => {
      roles.value = props.data.roles;
      type_id.value = props.data.type_id;
      type_name.value = props.data.type_name;
    })

    /*
      {
        [type_id]: {
          type_id: 1,
          type_name: "App"
          roles: {
            [role_id]: {
              role_id: 1,
              role_name: "Project Management",
              project_share: 0,
              project_share_id: 1 OR undefined
            }
          }
        }
      }
    */

    function forceZero(role) {
      if (role.project_share == '') {
        role.project_share = 0
      }
    }

    const total = computed(() => {
      let sum = 0;
      for (const role in roles.value) {
        sum += roles.value[role].project_share || 0;
      }
      return sum;
    })

    const updateProjectShare = async () => {
      try {
        for (const key in roles.value) {
          const { project_share, role_id, project_share_id: id } = roles.value[key];

          let response;

          if (id) {
            response = await putData(`/project_type_role/${id}`, { type_id: type_id.value, project_share, role_id, id });
            if (!(response.status >= 200 && response.status < 300)) {
              throw "Something happened!"
            }
          } else {
            response = await postData(`/project_type_role`, { type_id: type_id.value, project_share, role_id })
          }
        }

        alert(`${type_name.value}'s project share has been successfully updated!`)
      } catch (error) {
        alert(`There was an error! ${error}`);
      }

      return;
    }

    return {
      type_id, type_name, roles, total, updateProjectShare, forceZero
    }
  }
}
</script>