// ------------------------------------------------
//
// Use ProjectRole List
// Returns a global shared reference to the ProjectRole list
//
// ------------------------------------------------

import { ref } from "vue";

const listRef = ref([]);

//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
  return function (value) {
    name.value = value;
  };
}

//
//  Load ProjectRole list
//
import useAPI from "@/use/useAPI";
const { getData } = useAPI();
// loadProjectRoleData();
export function loadProjectRoleData() {
  getData("/project_role/")
    .then(setRef(listRef))
    .catch((err) => console.log(err.message));
}

loadFullProjectRoleData();
export function loadFullProjectRoleData() {
  (async () => {
    try {
      const list_role = await getData("/project_role/");
      const list_capacity = await getData("/Total_capacity/");
      const list_distribution = await getData("/Time_distribution/");

      const list_full = list_role.map((role) => {
        const { id: capacity_id, capacity } = list_capacity.find(
          (item) => item.role_id == role.id
        ) || {};
        const { id: distribution_id, distribution } = list_distribution.find(
          (item) => item.role_id == role.id
        ) || {};
        return {
          ...role,
          capacity,
          capacity_id,
          distribution,
          distribution_id,
        };
      });

      return list_full;
    } catch (error) {
      console.error(error.message);
    }
  })()
    .then(setRef(listRef))
    .catch((err) => console.log(err.message));
}


export default function useProjectRoleList() {
  //
  //  Return shared ProjectRole list
  //
  return listRef;
}
