<template>
  <main class="homeview" v-if="state.loggedIn">

    <Header msg="Forecast"/>
    
    <ProjectList :status="status"/>
    
    <article class="infographics-wrapper">
      <div>
        <router-link to="/graph">
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" class="svg-open">
            <g fill="none" fill-rule="evenodd">
              <circle fill="#0F2D4F" cx="10" cy="10" r="10"/>
              <g stroke="#FFF" stroke-linecap="square">
                <path d="M10 6v8M14 10H6"/>
              </g>
            </g>      
        </svg>
        </router-link>

        <Chart />

      </div>
      <div>

        <router-link to="/heatmap">
          <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" class="svg-open">
            <g fill="none" fill-rule="evenodd">
              <circle fill="#0F2D4F" cx="10" cy="10" r="10"/>
              <g stroke="#FFF" stroke-linecap="square">
                <path d="M10 6v8M14 10H6"/>
              </g>
            </g>     
        </svg>
        </router-link>

        <Heatmap />

      </div>
    </article> 

  </main>

  <main v-else-if="!state.loggedIn">
    <h1>It looks like you're not logged in</h1>
    <button @click="redirect" class="red">Go to login</button>
  </main>
</template>

<script>
// @ is an alias to /src
import ProjectList from '@/components/ProjectList.vue'
import Header from '@/components/Header.vue'
import Chart from '@/components/Chart.vue'
import Heatmap from '@/components/Heatmap.vue'

import useProjectList from '@/use/useProjectList.js'
import useStatusList from '@/use/useStatusList.js'
import useProjectCollection from '@/use/useProjectCollection.js'

import useState from '@/use/useState'
import useLogin from '@/use/useLogin'

import { onMounted, watchEffect } from 'vue'
import { useRoute, useRouter } from "vue-router";


export default {
  name: 'HomeView',
  props: ['status'],

  setup(){
    const projectList = useProjectList()
    const status = useStatusList()
    const statusList = useStatusList()
    const router = useRouter();

    const state = useState() 

    const col = useProjectCollection() 
    
    const getProjectItems = name => col.value[name]

    state.value.status = null
    
    if(!state.value.loggedIn) {
      router.push('/')
    }

    return { projectList, status, statusList, col, getProjectItems, state  }
  },

  components: {
    Header,
    ProjectList,
    Chart,
    Heatmap,
  },
  data() {
    return {
      keyword: null,
    };
  },
  computed: {
    filterProjects() {
      console.log('filter triggered')
      if (this.keyword) {
        return this.projects.filter(item => {
          return this.keyword
          .toLowerCase()
          .split(" ")
          .every(v => item.client_name.toLowerCase().includes(v))
        });
      } else {
        return this.projects;
      }
    }
  },
}
</script>
