<template>
  <label
    class="toggle button"
    :for="props.idName"
    :class="props.toggled ? 'toggle-on' : 'toggle-off'"
  >
    <input
      type="checkbox"
      :id="props.idName"
      :name="props.idName"
      v-show="false"
      @input="$emit('toggle')"
    />
    <span class="toggle-handle"></span>
    <span v-if="props.labelOn || props.labelOff" class="toggle-label">{{
      props.toggled ? props.labelOn : props.labelOff
    }}</span>
  </label>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    idName: { type: String, required: true },
    labelOn: String,
    labelOff: String,
    toggled: Boolean
  },
  setup(props) {
    return {props};
  },
};
</script>
