export default function useFormat() {
  const formatTimestampToDate = (timestamp) => {
    if (!timestamp) return null;

    // check that input is the right format, else return original data
    let timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/
    if (!timestampRegex.test(timestamp)) {
      return timestamp;
    }

    const dateParsed = Date.parse(timestamp);
    const intlFormat = new Intl.DateTimeFormat(["da"], {dateStyle: "short"})
    return intlFormat.format(dateParsed);
}

  const formatDateToTimestamp = (date) => {
    if (!date) return null;

    // check that input is the right format, else return original data
    let dateRegex = /^\d{2}\.\d{2}\.\d{4}$/
    if(!dateRegex.test(date)) return date;

    // date is formatted 'dd.mm.yyyy'
    let [dd, mm, yyyy] = date.split('.');
    let hr = 18;
    // timestamp is formatted '"2022-04-13T10:32:36.2160921"'
    const timestamp = `${yyyy}-${mm}-${dd}T${hr}:00:00.2160921`

    return timestamp;
  }

  return {formatTimestampToDate, formatDateToTimestamp}
}