import { reactive } from "@vue/reactivity";
import useValidators from '@/use/useValidators';
import useFormat from '@/use/useFormat';

const errors = reactive({});

export default function useFormValidation() {
    const { isEmpty, minLength, isEmail, isNumSimple, isNum, isDate } = useValidators();
    const { formatDateToTimestamp } = useFormat();

    const validateNameField = (fieldName, fieldValue) => {
        // errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : minLength(fieldName, fieldValue, 4)
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : ''
    }

     const validateEmailField = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isEmail(fieldName, fieldValue)
    }

    // const validatePhoneField = (fieldName, fieldValue) => {
    //     errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isNumSimple(fieldName, fieldValue)
    // }
    const validatePhoneField = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? "" : isNumSimple(fieldName, fieldValue)
    }

    const validateNumberField = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isNum(fieldName, fieldValue)
    }

    const validateDateField = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? "" : isDate(fieldName, fieldValue)
    }


    return { errors, validateNameField, validateEmailField, validatePhoneField, validateNumberField, validateDateField }
}