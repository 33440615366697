<template>
  <main>
    <Header :msg="project.client_name" :submsg="project.project_name" />
    <GoBack />

    <section
      class="project-container"
      :class="level == 1 ? 'one-col' : level < 4 ? 'two-col' : 'three-col'"
    >
      <article class="project-wrapper">
        <!-- <article class="add-project one-col" v-if="state.status == 'press_cred'">
            </article>
            <article class="add-project two-col" v-if="state.status == 'rfi'">
            </article>
            <article class="add-project two-col" v-if="state.status == 'rfp'">
                <SecondLevelProject />
            </article>
            <article class="add-project three-col" v-if="state.status == 'keynote'">
            </article>
            <article class="add-project three-col" v-if="state.status == 'sow'">
            </article> -->

        <fieldset
          class="dropdown status-dropdown"
          @click="editable ? toggleStatusDropdown() : ''"
          :class="showStatusDropdown ? 'active' : ''"
        >
          <label class="select-label">
            Project Status - {{ project.status_title }}
          </label>
          <fieldset class="dropdown-wrapper" v-show="showStatusDropdown">
            <label
              class="select-label"
              v-for="status in statusList"
              :key="status.id"
            >
              {{ status.status_title }}
              <input
                type="radio"
                @click="toggleStatusDropdown()"
                :value="status.id"
                v-model="project.status_id"
              />
            </label>
          </fieldset>
        </fieldset>

        <fieldset class="project-item" :class="editable ? 'edit' : ''">
          <label for="clientName">Client Name</label>
          <input
            type="text"
            id="clientName"
            :readonly="editable ? false : true"
            v-model="project.client_name"
            @input="validate.client_name()"
            @blur="validate.client_name()"
          />
          <p class="error-message" v-if="errors.client_name">
            {{ errors.client_name }}
          </p>

          <fieldset v-if="level > 1">
            <label for="projectName">project name</label>
            <input
              type="text"
              id="projectName"
              v-model="project.project_name"
              @input="validate.project_name()"
              @blur="validate.project_name()"
            />
            <p class="error-message" v-if="errors.project_name">
              {{ errors.project_name }}
            </p>

            <fieldset>
              <fieldset
                class="dropdown"
                @click="editable ? toggleTypeDropdown() : ''"
                :class="showTypeDropdown ? 'active' : ''"
              >
                <label class="dropdown-label"> Project type </label>
                <fieldset class="dropdown-wrapper" v-show="showTypeDropdown">
                  <label
                    class="select-label"
                    v-for="type in projectTypeList"
                    :key="type.id"
                  >
                    {{ type.type_name }}
                    <input
                      type="radio"
                      class="look-for-this"
                      @click="toggleTypeDropdown()"
                      :value="type.id"
                      v-model="project.type_id"
                    />
                  </label>
                </fieldset>
                <label for="project-type" class="project-type-label">{{
                  projectTypeName
                }}</label>
                <input
                  type="text"
                  id="project-type"
                  class="project-type-input"
                  readonly
                  :value="project.type_id"
                />
              </fieldset>
            </fieldset>
            <p class="error-message" v-if="errors.type_id">
              {{ errors.type_id }}
            </p>

            <label for="contactName">contact name</label>
            <input
              type="text"
              id="contactName"
              :readonly="editable ? false : true"
              v-model="project.contact_name"
              @input="validate.contact_name()"
              @blur="validate.contact_name()"
            />
            <p class="error-message" v-if="errors.contact_name">
              {{ errors.contact_name }}
            </p>

            <label for="phone">Phone number</label>
            <input
              type="text"
              id="phone"
              :readonly="editable ? false : true"
              v-model="project.contact_phone"
              @input="validate.contact_phone()"
              @blur="validate.contact_phone()"
            />
            <p class="error-message" v-if="errors.contact_phone">
              {{ errors.contact_phone }}
            </p>

            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              :readonly="editable ? false : true"
              v-model="project.contact_email"
              @input="validate.contact_email()"
              @blur="validate.contact_email()"
            />
            <p class="error-message" v-if="errors.contact_email">
              {{ errors.contact_email }}
            </p>

            <fieldset>
              <label for="radio-wrapper">Existing client</label>
              <fieldset class="radio-wrapper" id="radio-wrapper">
                <input
                  type="radio"
                  name="client_status"
                  id="radio-yes"
                  :value="false"
                  v-model="project.existing_client"
                  :disabled="editable ? false : true"
                />
                <label for="yes">Yes</label>
                <input
                  type="radio"
                  name="client_status"
                  id="radio-no"
                  :value="true"
                  v-model="project.existing_client"
                  :disabled="editable ? false : true"
                />
                <label for="no">No</label>
              </fieldset>
            </fieldset>
          </fieldset>
        </fieldset>

        <fieldset class="middle-wrapper" v-if="level > 1">
          <fieldset class="project-item" :class="editable ? 'edit' : ''">
            <fieldset v-if="level > 1">
              <label for="budget"
                >budget <span>in {{ budget }} € ranges</span></label
              >
              <input
                type="text"
                id="budget"
                :readonly="editable ? false : true"
                v-model="project.project_budget"
              />
              <p class="error-message" v-if="errors.project_budget">
                {{ errors.project_budget }}
              </p>

              <label for="deadline">deadline</label>
              <input
                type="text"
                id="deadline"
                :readonly="editable ? false : true"
                v-model="project.project_deadline"
                @input="validate.project_deadline()"
                @blur="validate.project_deadline()"
                placeholder="dd.mm.yyyy"
              />
              <p class="error-message" v-if="errors.project_deadline">
                {{ errors.project_deadline }}
              </p>
            </fieldset>

            <fieldset v-if="level >= 4">
              <label for="poNumber">po number</label>
              <input
                type="text"
                id="poNumber"
                :readonly="editable ? false : true"
                v-model="project.po_number"
                @input="validate.po_number()"
                @blur="validate.po_number()"
              />
              <p class="error-message" v-if="errors.po_number">
                {{ errors.po_number }}
              </p>
            </fieldset>
          </fieldset>
          <fieldset class="project-item" v-if="level > 3">
            <label>Created</label>
            <p>
              {{ project.created_by }},
              {{ formatTimestampToDate(project.created_at) }}
            </p>

            <label for="created_at">Last modified</label>
            <p>
              {{ project.modified_by }},
              {{ formatTimestampToDate(project.last_modified) }}
            </p>
          </fieldset>
        </fieldset>
        <fieldset
          class="project-item"
          :class="editable ? 'edit' : ''"
          v-if="level >= 4"
        >
          <fieldset
            v-for="role in getProjectWorkload"
            :key="role.id"
            class="grid-wrapper"
          >
            <label for="project-role">{{ role.role_name }}</label>
            <input
              type="text"
              id="project-role"
              :readonly="editable ? false : true"
              v-model="role.workload"
            />
            <span>hours</span>
          </fieldset>
        </fieldset>
      </article>

      <fieldset class="button-wrapper" v-if="!editable">
        <FormButton :ctaMsg="'Edit'" @click="enableEditState()" />
        <FormButton :ctaMsg="'Duplicate'" />
        <FormButton :ctaMsg="'Archive'" @click="archiveProjectShowModal()" />
      </fieldset>
      <fieldset class="button-wrapper" v-if="editable">
        <FormButton :ctaMsg="'Save'" @click="updateObject()" />
        <FormButton :ctaMsg="'Cancel'" @click="cancelUpdate()" />
      </fieldset>

      <SuccessMessage v-if="showSuccess" />

      <Modal
        msg="archive"
        v-if="showModal"
        @close-modal="showModal = false"
        @save-action="archiveProject()"
      />
    </section>
  </main>
</template>

<script>
import { computed, watch, ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

import Header from "@/components/Header.vue";
import GoBack from "@/components/GoBack.vue";
import FormButton from "@/components/FormButton.vue";
import Modal from "@/components/Modal.vue";
import SuccessMessage from "@/components/SuccessMessage.vue";

import useProjectList, {
  saveProjectData,
  archiveProjectData,
  loadProjectData
} from "@/use/useProjectList";
import useState from "@/use/useState";
import useStatusList from "@/use/useStatusList";
import useProjectWorkload from "@/use/useProjectWorkload";
import useProjectTypeList from "@/use/useProjectTypeList";
import useFormValidation from "@/use/useFormValidation";

import useProjectRoleList from "@/use/useProjectRoleList";

import useFormat from "@/use/useFormat";

import useAPI from "@/use/useAPI";
const { putData, postData } = useAPI();

export default {
  name: "ProjectItem",
  components: {
    Header,
    GoBack,
    FormButton,
    Modal,
    SuccessMessage,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const projectList = useProjectList();
    const statusList = useStatusList();
    const projectRoleList = useProjectRoleList();
    const projectWorkload = useProjectWorkload();
    const projectTypeList = useProjectTypeList();
    const state = useState();
    const statusValue = ref("");
    const getProjectWorkload = ref(null);
    const getProjectTypeName = ref(null);
    const getProjectStatusName = ref(null);
    const showModal = ref(false);
    const showSuccess = ref(false);
    const cacheData = ref(false);

    const level = ref(null);
    const budget = ref(null);

    const projectTypeName = ref("");

    const current = new Date();
    const date = moment(current).format();

    const editable = ref(false);

    const { formatTimestampToDate } = useFormat();

    if(!state.value.loggedIn) {
      router.push('/')
    }

    const project = computed(() => {
      const item =
        projectList.value.find((project) => project.id == route.params.id) ||
        {};
      item.project_deadline = formatTimestampToDate(item.project_deadline);
      return item;
    });

    watch(
      () => project.value,
      ({ status_id }) => {
        state.value.status = status_id;
      }
    );

    watchEffect(() => {
      if (route.params.edit) {
        editable.value = true;
      } else {
        editable.value = false;
      }

      statusList.value.forEach((elm) => {
        if (route.params.status == elm.id) {
          state.value.status = elm.status_name;
        }
      });

      if (!cacheData.value) {
        getProjectWorkload.value = projectWorkload.value.filter(
          (item) => item.project_id == project.value.id
        );

        let workloadArrayUpdate = [];

        projectRoleList.value.forEach((role) => {
          const template = {
            id: null,
            workload: 0,
            role_id: role.id,
            role_name: role.role_name,
            project_id: project.value.id,
            project_role: null,
            project: null,
          };

          const roleIndex = getProjectWorkload.value.findIndex(
            (obj) => obj.role_id == role.id
          );

          let merged = template;

          if (roleIndex >= 0) {
            merged = {
              ...template,
              ...getProjectWorkload.value[roleIndex],
            };
          }

          workloadArrayUpdate.push(merged);
        });

        getProjectWorkload.value = workloadArrayUpdate;
      }

      getProjectTypeName.value = projectTypeList.value.filter(
        (item) => item.id == project.value.type_id
      );
      getProjectTypeName.value.forEach((x) => {
        project.value.type_name = x.type_name;
      });

      getProjectStatusName.value = statusList.value.filter(
        (item) => item.id == project.value.status_id
      );
      getProjectStatusName.value.forEach((el) => {
        if (el.status_name == "press_cred") {
          project.value.status_title = "Press Cred";
          level.value = 1;
        } else if (el.status_name == "rfi") {
          project.value.status_title = "RFI";
          level.value = 2;
          budget.value = "100.000";
        } else if (el.status_name == "rfp") {
          project.value.status_title = "RFP";
          level.value = 3;
          budget.value = "100.000";
        } else if (el.status_name == "keynote") {
          project.value.status_title = "Brief/Keynote";
          level.value = 4;
          budget.value = "10.000";
        } else if (el.status_name == "sow") {
          project.value.status_title = "SOW";
          level.value = 5;
          budget.value = "1000";
        }
      });

      statusList.value.forEach((el) => {
        if (el.status_name == "press_cred") {
          el.status_title = "Press Cred";
        } else if (el.status_name == "rfi") {
          el.status_title = "RFI";
        } else if (el.status_name == "rfp") {
          el.status_title = "RFP";
        } else if (el.status_name == "keynote") {
          el.status_title = "Brief/Keynote";
        } else if (el.status_name == "sow") {
          el.status_title = "SOW";
        }
      });

      projectTypeList.value.forEach((el) => {
        if (project.value.type_id == el.id) {
          projectTypeName.value = el.type_name;
        }
      });
    });

    // Validate input fields
    const {
      validateNameField,
      validateEmailField,
      validatePhoneField,
      validateNumberField,
      validateDateField,
      errors,
    } = useFormValidation();

    const validate = (() => {
      return {
        client_name: () =>
          validateNameField("client_name", project.value.client_name),
        project_name: () =>
          validateNameField("project_name", project.value.project_name),
        type_id: () => validateNameField("type_id", project.value.type_id),
        contact_name: () =>
          validateNameField("contact_name", project.value.contact_name),
        contact_phone: () =>
          validatePhoneField("contact_phone", project.value.contact_phone),
        contact_email: () =>
          validateEmailField("contact_email", project.value.contact_email),
        project_budget: () =>
          validateNumberField("project_budget", project.value.project_budget),
        po_number: () =>
          validateNumberField("po_number", project.value.po_number),
        project_deadline: () =>
          validateDateField("project_deadline", project.value.project_deadline),
      };
    })();

    const fieldsPerStatus = {
      "Press Cred": ["client_name"],
      "RFI": [
        "client_name",
        "project_name",
        "type_id",
        "contact_name",
        "contact_phone",
        "contact_email",
        "existing_client",
        "project_budget",
        "project_deadline",
      ],
      "RFP": [
        "client_name",
        "project_name",
        "type_id",
        "contact_name",
        "contact_phone",
        "contact_email",
        "existing_client",
        "project_budget",
        "project_deadline",
      ],
      'Brief/Keynote': [
        "client_name",
        "project_name",
        "type_id",
        "contact_name",
        "contact_phone",
        "contact_email",
        "existing_client",
        "project_budget",
        "project_deadline",
        "po_number",
        "project_workload",
      ],
      'SOW': [
        "client_name",
        "project_name",
        "type_id",
        "contact_name",
        "contact_phone",
        "contact_email",
        "existing_client",
        "project_budget",
        "project_deadline",
        "po_number",
        "project_workload",
      ],
    };

    const validateAll = () => {


      // check for errors
      for (const key in validate) {
        if (Object.hasOwnProperty.call(validate, key)) {
        // if field is used, error check it, else clear its errors
          if (fieldsPerStatus[project.value.status_title].includes(key)) {
            const cb = validate[key];
            cb();

          } else {
            errors[key] = ''
          }

        }
      }

      const hasError = Object.values(errors).some((error) => error !== "");
      return hasError;
    };

    // Handle dropdown function
    const showTypeDropdown = ref(false);
    const toggleTypeDropdown = () => {
      showTypeDropdown.value = !showTypeDropdown.value;
    };
    const showStatusDropdown = ref(false);
    const toggleStatusDropdown = () => {
      showStatusDropdown.value = !showStatusDropdown.value;
    };

    const enableEditState = () => {
      let { id, status_id } = project.value;
      router.push({
        name: "projects",
        params: { id: id, status: status_id, edit: "edit" },
      });
      editable.value = true;
    };

    const updateObject = async () => {
      console.log("Update object");

      let response;
      let { id, status_id } = project.value;
      project.value.modified_by = state.value.username;

      if (validateAll()) return;

      try {
        if (!id) {
          console.log("no id");
        } else {
          saveProjectData(project.value);

          getProjectWorkload.value.forEach((itm) => {
            const { id: project_id } = project.value;
            const { id, role_id, role_name, workload } = itm;

            if (id) {
              putData(`/project_workload/${id}`, {
                id,
                project_id,
                role_id,
                workload,
              });
            } else {
              postData("/project_workload", {
                project_id,
                role_id,
                workload,
              });
            }

            cacheData.value = true;
          });

          showSuccess.value = true;
          setTimeout(() => {
            showSuccess.value = false;
            loadProjectData();
            useProjectWorkload();
          }, 1500);
        }
      } catch (error) {
        console.log(error);
      }

      if (route.params.close) {
        router.push("/home");
      } else {
        router.push({
          name: "projects",
          params: { id: id, status: status_id },
        });
      }
      editable.value = false;
    };

    const cancelUpdate = () => {
      let { id, status_id } = project.value;
       if (route.params.close) {
        router.push({name: "home"});
      } else {
        router.push({
          name: "projects",
          params: { id: id, status: status_id },
        });
      }

      editable.value = false;
    };

    const archiveProjectShowModal = () => {
      showModal.value = true;
    };
    const archiveProject = () => {
      console.log("archiving project");
      archiveProjectData(project.value);
    };

    return {
      project,
      state,
      statusValue,
      statusList,
      projectRoleList,
      getProjectWorkload,
      projectTypeList,
      toggleTypeDropdown,
      toggleStatusDropdown,
      showTypeDropdown,
      showStatusDropdown,
      getProjectTypeName,
      getProjectStatusName,
      editable,
      enableEditState,
      formatTimestampToDate,
      updateObject,
      projectTypeName,
      showModal,
      archiveProject,
      archiveProjectShowModal,
      cancelUpdate,
      level,
      budget,
      showSuccess,
      errors,
      validate,
    };
  },
};
</script>
