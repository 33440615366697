<template>
  <tr>
    <td class="type-name">
      <form class="hidden-form" @submit.prevent="saveType()" :id="`type-${id}`" />
      <input type="text" :form="`type-${id}`" name="type-name" v-model.trim="type_name">
    </td>
    <td class="actions">
      <div>
        <button v-if="isExistingType" type="button" :form="`type-${id}`" :class="canDelete ? 'delete' : 'hidden'" @click="deleteType()">Delete</button>
        <button :form="`type-${id}`" :class="isExistingType ? '' : 'create'">{{ isExistingType ? "Update" : "Create New Type" }}</button>
      </div>
    </td>
  </tr>
</template>

<script>
import { ref, watchEffect, reactive, computed, watch } from 'vue'

import useAPI from '@/use/useAPI'
import { loadProjectTypeData } from '@/use/useProjectTypeList';
const { putData, deleteData, postData, getData } = useAPI();

export default {
  name: 'Project Type',
  props: ['data'],

  setup(props) {
    const id = ref(props.data.id);

    const type_name = ref(props.data.type_name);

    const canDelete = ref(props.data.hasProject == undefined ? false : !(props.data.hasProject));

    const isExistingType = computed(() => props.data.id !== undefined);

    const saveType = async () => {
      try {
        if (!type_name.value) {
          alert("Your type needs a name!")
          return;
        }

        let response;

         if (id.value) {
            response = await putData(`/project_type/${id.value}`, { id: id.value, type_name: type_name.value });
          } else {
            response = await postData(`/project_type`, { type_name: type_name.value })
          }

        let wasSuccessful = (response.status >= 200 && response.status < 300);

        if (wasSuccessful) {
          alert(`${type_name.value}'s details were successfully ${isExistingType ? 'updated' : 'created'}`);
        } else {
          throw response.status;
        }

        // reload data to stay updated, relevant when creating new roles
        loadProjectTypeData();

        // reset fields if it's the 'create new' card
        if (!isExistingType.value) {
          id.value = undefined;
          type_name.value = "";
        }
      } catch (error) {
        console.log(`There was an error! ${error}`);
      }
    }

    const deleteType = async () => {
      try {
        if (!confirm(`Do you really want to delete ${type_name.value}?`)) {
          return;
        }

        let response = await deleteData(`/project_type/${id.value}`)

        let wasSuccessful = (response.status >= 200 && response.status < 300);

        if (wasSuccessful) {
          alert(`${type_name.value} was successfully deleted`);
        }

        // reload data to stay updated, relevant when creating new roles
        loadProjectTypeData();

      } catch (error) {
        console.log(error);
      }
    }

    return {
      id,
      type_name,
      isExistingType,
      saveType,
      deleteType,
      canDelete
    }
  }
}
</script>