import AuthService from '../services/msal2-authService';
import useState from '@/use/useState'
import parseJwt from '../services/parseJwt';
import router from '@/router'
// import { useRouter } from 'vue-router'
// import router from '@/router'

import { ref } from 'vue'


const state = useState()
const authService = new AuthService();


async function login() {
    try {
        await authService.login()
        await authService.getAccessToken()

        // Get logged in users username
        state.value.username = (parseJwt(authService.accessToken)).name

        // Get logged in users scope (Admin or user)
        let getScope = (parseJwt(authService.accessToken)).scp
        getScope = getScope.includes("application.settings") ? "Admin" : getScope.includes("application.access") ? "User" : ""
        state.value.scope = getScope
        
    } catch (error) {
        console.log(error)
    }
}


export default function useLogin() {
    
    return login()
}