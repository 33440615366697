<template>
    <button class="red">
        {{ ctaMsg }}
    </button>
</template>

<script>
export default {
    name: 'FormButton',
    props: {
        ctaMsg: String,
    }
}
</script>