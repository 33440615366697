<template>
  <form @submit.prevent novalidate class="add-project" v-if="state.status">
    <fieldset class="project-item">
      <input type="text" id="status" hidden v-model="modelRef.status_id" />

      <label for="clientName">Client Name</label>
      <input
        type="text"
        id="clientName"
        v-model="modelRef.client_name"
        @input="validate.client_name()"
        @blur="validate.client_name()"
      />
      <p class="error-message" v-if="errors.client_name">
        {{ errors.client_name }}
      </p>

      <fieldset>
        <label for="projectName">project name</label>
        <input
          type="text"
          id="projectName"
          v-model="modelRef.project_name"
          @input="validate.project_name()"
          @blur="validate.project_name()"
        />
        <p class="error-message" v-if="errors.project_name">
          {{ errors.project_name }}
        </p>

        <fieldset
          class="dropdown"
          :class="showDropdown ? 'active' : ''"
          @click="toggleDropdown()"
        >
          <label class="dropdown-label"> Project type </label>
          <fieldset class="dropdown-wrapper" v-show="showDropdown">
            <label
              class="select-label"
              v-for="type in projectTypeList"
              :key="type.id"
            >
              {{ type.type_name }}
              <input
                type="radio"
                @click="
                  toggleDropdown();
                  errors.type_id = '';
                "
                :value="type.id"
                v-model="modelRef.type_id"
              />
            </label>
          </fieldset>
          <label for="project-type" class="project-type-label">{{
            projectTypeName
          }}</label>
          <input
            type="text"
            id="project-type"
            class="project-type"
            :value="modelRef.type_id"
            readonly
          />
        </fieldset>
        <p class="error-message" v-if="errors.type_id">
          {{ errors.type_id }}
        </p>

        <label for="contactName">contact name</label>
        <input
          type="text"
          id="contactName"
          v-model="modelRef.contact_name"
          @input="validate.contact_name()"
          @blur="validate.contact_name()"
        />
        <p class="error-message" v-if="errors.contact_name">
          {{ errors.contact_name }}
        </p>

        <label for="phone">Phone number</label>
        <input
          type="text"
          id="phone"
          v-model="modelRef.contact_phone"
          @input="validate.contact_phone()"
          @blur="validate.contact_phone()"
        />
        <p class="error-message" v-if="errors.contact_phone">
          {{ errors.contact_phone }}
        </p>

        <label for="email">Email</label>
        <input
          type="email"
          id="email"
          v-model="modelRef.contact_email"
          @input="validate.contact_email()"
          @blur="validate.contact_email()"
        />
        <p class="error-message" v-if="errors.contact_email">
          {{ errors.contact_email }}
        </p>

        <fieldset class="radio-wrapper" id="radio-wrapper">
          <input
            type="radio"
            name="client_status"
            :value="true"
            id="radio-yes"
            v-model="modelRef.existing_client"
          />
          <label for="yes">Yes</label>
          <input
            type="radio"
            name="client_status"
            :value="false"
            id="radio-no"
            v-model="modelRef.existing_client"
            checked
          />
          <label for="no">No</label>
        </fieldset>
      </fieldset>
    </fieldset>
    <fieldset class="project-item">
      <label for="budget">budget <span>in 100.000 € ranges</span></label>
      <input type="text" id="budget" v-model="modelRef.project_budget" />
      <p class="error-message" v-if="errors.project_budget">
        {{ errors.project_budget }}
      </p>

      <label for="deadline">deadline</label>
      <input
        type="text"
        id="deadline"
        placeholder="dd.mm.yyyy"
        v-model="modelRef.project_deadline"
        @input="validate.project_deadline()"
        @blur="validate.project_deadline()"
      />
      <p class="error-message" v-if="errors.project_deadline">
        {{ errors.project_deadline }}
      </p>
    </fieldset>

    <fieldset class="project-item">
      <fieldset
        v-for="role in projectRoleList"
        :key="role.id"
        class="grid-wrapper"
      >
        <label for="project-role">{{ role.role_name }}</label>
        <input type="text" id="project-role" v-model="role.workload" />
        <span>hours</span>
      </fieldset>

      <!-- <div v-for="x in projectRoleList">{{ x }}<br><br></div> -->

    </fieldset>

    <fieldset class="button-wrapper">
      <FormButton
        :ctaMsg="'cancel'"
        type="button"
        @click="cancelNewProject()"
      />
      <FormButton :ctaMsg="'save'" type="submit" @click="submitForm()" />
    </fieldset>
  </form>
  <Modal msg="cancel" v-if="showModal" @close-modal="showModal = false" />
  <SuccessMessage v-if="showSuccess" />
</template>

<script>
// Import components
import FormButton from "@/components/FormButton.vue";
import Modal from "@/components/Modal.vue";
import SuccessMessage from "@/components/SuccessMessage.vue";

// Import from Use folder
import useStatusList from "@/use/useStatusList.js";
import useState from "@/use/useState";
import { loadProjectData } from "@/use/useProjectList";
import useProjectTypeList from "@/use/useProjectTypeList";
import useProjectRoleList from "@/use/useProjectRoleList";
import useFormValidation from "@/use/useFormValidation";

// Import vue things
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

import useAPI from "@/use/useAPI";
const { postData } = useAPI();
import useFormat from "@/use/useFormat";

export default {
  name: "NewProject",
  props: {},
  components: {
    FormButton,
    Modal,
    SuccessMessage,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const statusList = useStatusList();
    const projectTypeList = useProjectTypeList();
    const projectRoleList = useProjectRoleList();
    const state = useState();

    const statusId = ref(null);
    const projectTypeName = ref("");
    const roleWorkload = ref([]);
    const showModal = ref(false);
    const showSuccess = ref(false);

    const current = new Date();
    const date = moment(current).format();

    const { formatTimestampToDate, formatDateToTimestamp } = useFormat();

    const modelRef = ref({
      client_name: "",
      created_by: state.value.username,
      created_at: date,
      last_modified: date, // SHOULD BE NULL OR EMPTY
      project_name: "",
      project_deadline: null,
      project_budget: 0,
      contact_name: "",
      contact_phone: null,
      contact_email: "",
      existing_client: false,
      po_number: null,
      project_deleted: null,
      project_archived: null,
      type_id: null,
      status_id: statusId,
    });

    const modelRoleRef = ref([
      {
        project_id: null,
        role_id: null,
        workload: 3,
      },
    ]);

    watchEffect(() => {
      const { id } = route.params;
      state.value.status = id;

      // Find and set matching status_id for posting to the db
      statusList.value.forEach((el) => {
        if (state.value.status == el.status_name) {
          statusId.value = el.id;
        }
      });

      // Function to display type_name in form instead of id
      projectTypeList.value.forEach((el) => {
        if (modelRef.value.type_id == el.id) {
          projectTypeName.value = el.type_name;
        }
      });
    });

    // Handle dropdown function
    const showDropdown = ref(false);
    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    // Validate input fields
    const {
      validateNameField,
      validateEmailField,
      validatePhoneField,
      validateNumberField,
      validateDateField,
      errors,
    } = useFormValidation();

    const validate = (() => {
      return {
        client_name: () =>
          validateNameField("client_name", modelRef.value.client_name),
        project_name: () =>
          validateNameField("project_name", modelRef.value.project_name),
        type_id: () => validateNameField("type_id", modelRef.value.type_id),
        contact_name: () =>
          validateNameField("contact_name", modelRef.value.contact_name),
        contact_phone: () =>
          validatePhoneField("contact_phone", modelRef.value.contact_phone),
        contact_email: () =>
          validateEmailField("contact_email", modelRef.value.contact_email),
        project_budget: () =>
          validateNumberField("project_budget", modelRef.value.project_budget),
        // po_number: () => validateNumberField("po_number", modelRef.value.po_number),
        project_deadline: () =>
          validateDateField(
            "project_deadline",
            modelRef.value.project_deadline
          ),
      };
    })();

    const validateAll = () => {
      // check for errors
      for (const key in validate) {
        if (Object.hasOwnProperty.call(validate, key)) {
          const cb = validate[key];
          cb();
        }
      }

      console.log(Object.entries(errors));
      const hasError = Object.values(errors).some((error) => error !== "");

      return hasError;
    };

    const submitForm = async () => {
      // Post project data to database - projects table
      let response;

      let { id } = modelRef.value;
      console.log(id)

      const hasErrors = validateAll();
      if (hasErrors) return;

      const submitData = {
        ...modelRef.value,
        project_deadline: formatDateToTimestamp( modelRef.value.project_deadline ),
      };

      try {
        if (!id) {
          response = await postData("/projects", submitData);
        }
        let data = await response.json()
        
        modelRef.value = {
          ...data,
          project_deadline: formatTimestampToDate(data.project_deadline),
        };


        // Post project role data to database - projects workload table
        projectRoleList.value.forEach((itm) => {
          const { id: project_id } = modelRef.value;
          const { id: role_id, role_name, workload } = itm;

          postData("/project_workload", {
            workload,
            role_id,
            project_id,
          })
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
        });

        showSuccess.value = true;
        
        setTimeout(() => {
          showSuccess.value = false;
          loadProjectData();
          router.push("/home");
        }, 1500);

      } catch (error) {
        console.log(error)
      }

    };

    const cancelNewProject = () => {
      showModal.value = true;
    };

    return {
      state,
      statusList,
      projectTypeList,
      projectRoleList,
      modelRef,
      modelRoleRef,
      submitForm,
      showDropdown,
      toggleDropdown,
      projectTypeName,
      roleWorkload,
      showModal,
      cancelNewProject,
      validate,
      errors,
      showSuccess,
    };
  },
};
</script>
