<template>
    {{ displayWorkhours }}
    <div class="heatmap">
        <article class="roles">
            <fieldset class="role-wrapper" v-for="role in roleList" :key="role">
                <p>{{ role.role_name }}</p>
            </fieldset>
        </article>
        <div class="heatmap-wrapper allow-scrollbar">
            <article class="weeks-wrapper" v-for="week in 52" :key="week" :data-week="week" ref="weeksRef" :class="currentWeek == week ? 'current-week' : ''">
                <p>{{ week }}</p>
                <div class="role" v-for="role in roleList" :key="role.id" :data-attr="role.id" ref="divs"></div>
            </article>
        </div>
    </div>
</template>

<script>
import useProjectRoleList from '@/use/useProjectRoleList'
import useHeatList from '@/use/useHeatList.js'
import getWeekNumber from '@/use/getWeekNumber'
import { ref, watchEffect, watch } from 'vue'
export default {
    name: 'Heatmap',
    setup() {
        const roleList = useProjectRoleList()
        const heatList = useHeatList()
        const heatWeek = ref([])
        const totalWeeks = ref([])
        const heatlistModel = ref([])
        const displayWorkhours = ref(null)
        const { getWeekNo } = getWeekNumber()
        const currentWeek = getWeekNo();
        let result

        const divs = ref([])

        // Show and hide heatmap workhours values - not used now!!
        const findWorkhours = (itm) => {
            const numb = itm.Workhours
            // show max 2 digits
            itm.displayedWorkhours = Math.round(numb * 100) / 100
        }
        const hideWorkhours = (itm) => {
            itm.displayedWorkhours = null
        }

        const weeksRef = ref([]);
        watchEffect(() => {
            if (weeksRef.value.length && heatWeek.value.length) {
                const elm = weeksRef.value[currentWeek];
                elm.scrollIntoView({inline: "center"});
                elm.setAttribute('data-scrollTo', 'true');
                setTimeout(() => {
                    elm.removeAttribute('data-scrollTo');
                }, 1000);

            }
        })

        watchEffect(() => {
            heatList.value.forEach(el => {
                heatWeek.value.push(el.Week)
            })
            for (let i = 0; i < 52; i++) {
                totalWeeks.value.push(i + 1)
            }

            totalWeeks.value.forEach(week => {
                return result = heatWeek.value.filter(itm => itm == week)
            })


            heatList.value.forEach(el => {
                el.Role_list.forEach(itm => {
                    el.role_id = itm.Role_id
                    itm.week = el.Week
                    const color = itm.Workhours * 100;

                    if (color < 10) {
                        itm.colorValue = '#EEF8F3'
                    } else if (color < 20) {
                        itm.colorValue = '#DBF2E5'
                    } else if (color < 30) {
                        itm.colorValue = '#BDE9C9'
                    } else if (color < 40) {
                        itm.colorValue = '#9CDEAD'
                    } else if (color < 50) {
                        itm.colorValue = '#78D09C'
                    } else if (color < 60) {
                        itm.colorValue = '#4BC07B'
                    } else if (color < 70) {
                        itm.colorValue = '#1EB05A'
                    } else if (color < 80) {
                        itm.colorValue = '#407F53'
                    } else if (color < 90) {
                        itm.colorValue = '#FCC399'
                    } else if (color < 100) {
                        itm.colorValue = '#FBA566'
                    } else if (color < 110) {
                        itm.colorValue = '#FA8733'
                    } else if (color < 120) {
                        itm.colorValue = '#F96800'
                    } else if (color < 130) {
                        itm.colorValue = '#D12121'
                    } else if (color < 140) {
                        itm.colorValue = '#C70D0D'
                    } else if (color < 150) {
                        itm.colorValue = '#B71B06'
                    } else if (color < 160) {
                        itm.colorValue = '#AB1E20'
                    } else if (color < 170) {
                        itm.colorValue = '#991C0B'
                    } else if (color < 180) {
                        itm.colorValue = '#881404'
                    } else if (color < 190) {
                        itm.colorValue = '#720801'
                    } else {
                        itm.colorValue = '#520702'
                    }
                    heatlistModel.value.push(itm)
                })
            })

            divs.value.forEach(x => {
                const parentWeek = x.parentNode.getAttribute('data-week')
                const roleId = x.getAttribute('data-attr')
                heatlistModel.value.filter(itm => {
                    if (itm.week == parentWeek && itm.Role_id == roleId) {
                        x.style.backgroundColor = itm.colorValue
                    }
                })
            })

        })


        return { roleList, heatList, heatWeek, totalWeeks, result, heatlistModel, displayWorkhours, findWorkhours, hideWorkhours, divs, weeksRef, currentWeek }
    },
}
</script>
