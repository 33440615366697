export default function useValidators() {
    const isEmpty = (fieldName, fieldValue) => {
    //  return !fieldValue ? "The " + fieldName + " field is required" : "";
     return !fieldValue ? "This field is required." : "";
    }

    const minLength = (fieldName, fieldValue, min) => {
         return fieldValue.length < min ? `The ${fieldName} field must be atleast ${min} characters long` : "";
   }

   const isEmail = (fieldName, fieldValue) => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(fieldValue) ? "The input is not a valid email address." : "";
}

    const isNumSimple = (fieldName, fieldValue) => {
        let isNum = /^\d+$/.test(fieldValue);
        return !isNum ? "The input is not a valid phone number. Please only insert numbers." : "";
    }
    const isNum = (fieldName, fieldValue) => {
        let isNum = /^\d+$/.test(fieldValue);
        return !isNum ? `Please add a ${fieldName}` : "";
    }

    const isDate =  (fieldName, fieldValue) => {
        // format dd.mm.yyyy
        let isDate = /^\d{2}\.\d{2}\.\d{4}$/.test(fieldValue);
        return !isDate ? "The input must be formatted as dd.mm.yyyy" : "";
    }


   return {isEmpty, minLength, isEmail, isNumSimple, isNum, isDate}
 }