import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import NewProject from '@/views/NewProjectView.vue'
import ProjectItem from '@/components/ProjectItem.vue'
import BarChart from '@/views/BarChartView.vue'
import HeatmapView from '@/views/HeatmapView.vue'
import DevView from '@/views/DevView.vue'
import AdminView from '@/views/AdminView.vue'
import MSAL2 from '@/components/MSAL2.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/projects/:id?/:status?/:edit?/:close?',
    name: 'projects',
    component: ProjectItem
  },
  {
    path: '/new-project/:id?/:title?',
    name: 'new-project',
    component: NewProject,
  },
  {
    path: '/graph',
    name: 'graph',
    component: BarChart,
  },
  {
    path: '/heatmap',
    name: 'heatmap',
    component: HeatmapView,
  },
  {
    path: '/dev',
    name: 'dev',
    component: DevView
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminView
  },
  {
    path: '/msal2',
    name: 'msal2',
    component: MSAL2
  },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
]

const router = createRouter({
  // Creates web history
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
