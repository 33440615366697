<template>
  <tr>
    <td class="role-name">
      <form class="hidden-form" @submit.prevent="saveRole()" :id="`role-${id}`" />
      <input type="text" :form="`role-${id}`" name="role-name" v-model.trim="name">
    </td>
    <td class="total-capacity">
      <input type="number" :form="`role-${id}`" min="0" name="total-capacity" v-model.number="capacity" @blur="forceZero">
    </td>
    <td class="time-distribution">
      <fieldset>
        <input v-for="(item, i) in distribution" :key="i" type="checkbox" true-value="1" :form="`role-${id}`" false-value="0" name="time-distribution" v-model="item.value" />
      </fieldset>
    </td>
    <td class="actions">
      <div>
        <button v-if="isExistingRole" type="button" :form="`role-${id}`" :class="canDelete ? 'delete' : 'hidden'" @click="deleteRole()">Delete</button>
        <button :form="`role-${id}`" :class="isExistingRole ? '' : 'create'">{{ isExistingRole ? "Update" : "Create New Role" }}</button>
      </div>
    </td>
  </tr>
</template>

<script>
import { ref, watchEffect, reactive, computed, watch } from 'vue'

import useAPI from '@/use/useAPI'
import { loadFullProjectRoleData } from '@/use/useProjectRoleList';
const { putData, deleteData, postData, getData } = useAPI();

export default {
  name: 'Project Role',
  props: ['data'],

  setup(props) {
    const id = ref(props.data.id);
    const distribution_id = ref(props.data.distribution_id);
    const capacity_id = ref(props.data.capacity_id);

    // creates an array of 10 objects with value 0 as default, then checks for actual data, and assigned it to distribution
    const default_distribution = Array(10).fill().map(() => ({ value: '0' }));
    const data_distribution = props.data.distribution && props.data.distribution.split(",").map(value => ({ value }));
    const distribution = reactive(data_distribution || default_distribution);

    const name = ref(props.data.role_name);

    const capacity = ref(props.data.capacity || 0);

    const isExistingRole = computed(() => props.data.id !== undefined);

    const canDelete = ref(props.data.hasProject == undefined ? false : !(props.data.hasProject));


    function forceZero() {
      if (capacity.value == '') {
        capacity.value = 0
      }
    }

    const saveRole = async () => {
      try {
        if (!name.value) {
          alert("Your role needs a name!")
          return;
        }

        async function pushToTable(endpoint, data, refToUpdate) {
          let response;
          if (!data.id) {
            response = await postData(`${endpoint}`, data);
            refToUpdate.value = response.id;
          } else {
            response = await putData(`${endpoint}/${data.id}`, data);
          }
          return response;
        }

        // project_role must be done first to have role_id for other tables
        await pushToTable("/project_role", { id: id.value, role_name: name.value }, id)

        await pushToTable("/Total_capacity", { id: capacity_id.value, capacity: capacity.value, role_id: id.value }, capacity_id)

        let td_string = distribution.map(data => data.value).join(',');
        await pushToTable("/Time_distribution", { id: distribution_id.value, distribution: td_string, role_id: id.value }, distribution_id)

        alert(`${name.value}'s details were successfully ${isExistingRole ? 'updated' : 'created'}`);

        // reload data to stay updated, relevant when creating new roles
        loadFullProjectRoleData();

        // reset fields if it's the 'create new' card
        if (!isExistingRole.value) {
          id.value = undefined;
          distribution_id.value = undefined;
          capacity_id.value = undefined;
          name.value = "";
          capacity.value = 0;
          distribution.map(item => item.value = 0);
        }


      } catch (error) {
        console.log(`There was an error! ${error}`);
      }
    }

    const deleteRole = async () => {
      try {
        if (!confirm(`Do you really want to delete ${name.value}?`)) {
          return;
        }

        let roleResponse = await deleteData(`/Project_role/${id.value}`)
        let distResponse = await deleteData(`/Time_distribution/${distribution_id.value}`)
        let capResponse = await deleteData(`/Total_capacity/${capacity_id.value}`)

        let wasSuccessful = [roleResponse, distResponse, capResponse].every(resp => resp.status >= 200 && resp.status < 300);

        if (wasSuccessful) {
          alert(`${name.value} was successfully deleted`);
        }

        // reload data to stay updated, relevant when creating new roles
        loadFullProjectRoleData();

      } catch (error) {
        console.log(error);
      }
    }

    return {
      id,
      name,
      distribution,
      capacity,
      isExistingRole,
      saveRole,
      deleteRole,
      canDelete,
      forceZero
    }
  }
}
</script>