<template>
    <article>
        <article class="buttons-wrapper">
            <button @click="filterUsers">{{ filterState }}</button>

            <input v-model="searchQuery" class="button" placeholder="Search...">

            <router-link to="/new-project/" class="button">
                New Project
            </router-link>

        </article>

        <ul class="status-list">
            <li v-for="{ status_title, status_name, id } in statusList" :key="id"
            class="status-card board"
            :class="status_name"
            :id="status_name + '-' + id"
            @dragover.prevent
            @drop.prevent="dragDrop(id)"
            >
                <h2>{{ status_title }}</h2>
                <button class="add-project" @click="nextStep(status_name, title)">
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#FFF" fill="none" fill-rule="evenodd" stroke-linecap="square">
                            <path d="M10 .667v18.666M.667 10h18.666"/>
                        </g>
                    </svg>
                </button>

                <slot />
                <div class="scroll-wrapper">
                     <a v-for="project in getProjectItems[id]"
                        :href="`/projects/${project.id}/${project.status_id}`"
                        class="project-detail card"
                        :key="project.id"
                        draggable="true"
                        @dragstart="dragStart(project)"
                        @dragover.stop>
                        {{ project.client_name}}
                    </a>
                     <!-- <router-link v-for="project in getProjectItems[id]"
                        class="project-detail card"
                        :key="project.id"
                        :to="{
                            name: 'projects',
                            params: { id: project.id, status: project.status_id }
                        }"
                        draggable="true"
                        @dragstart="dragStart(project)"
                        @dragover.stop>
                        {{ project.client_name}}
                    </router-link> -->
                </div>

                <div class="arrow">
                    <svg width="22" height="10" xmlns="http://www.w3.org/2000/svg">
                        <path d="m1 0 10 10L21 0" stroke="#FFF" fill="none"/>
                    </svg>
                </div>
            </li>
        </ul>
    </article>
</template>

<script>
import useProjectList, { saveProjectData, loadProjectData } from '@/use/useProjectList.js'
import useStatusList from '@/use/useStatusList.js'
import useProjectCollection from '@/use/useProjectCollection.js'
import useState from '@/use/useState'

import { useRouter, useRoute } from 'vue-router'
import { ref, computed, watchEffect } from 'vue'


export default {
    name: 'ProjectList',
    props: ['id', 'status'],
    step: '',
    created() {
        loadProjectData();
    },
    setup() {
        const route = useRoute();
        const router = useRouter()

        const statusList = useStatusList()
        const projectCol = useProjectCollection()
        const projectList = useProjectList()
        const state = useState()

        const searchQuery = ref('')
        const filteredProjects = ref(false)

        const filterState = ref('My Projects')

        const filterUsers = () => {
            filteredProjects.value = !filteredProjects.value
            filterState.value = filteredProjects.value ? 'My Projects' : 'All Projects'
        }

        const filteredProjectCol = computed(()=>{
                    return !filteredProjects.value    // no filter
                        ? projectCol.value        // show full collection
                        : Object.entries(projectCol.value)
                            .reduce((acc, [key, valueList] )=> {
                                acc[key] = valueList
                                .filter(item => {
                                    return item.created_by == state.value.username
                                })
                                return acc
                            }, {})
                })

        const getProjectItems = computed(()=>{
            return !searchQuery.value    // no search text
                ? filteredProjectCol.value        // show full collection
                : Object.entries(filteredProjectCol.value)
                    .reduce((acc, [key, valueList] )=> {
                        acc[key] = valueList
                        .filter(item => {
                            return item.client_name.toLowerCase()
                            .includes(searchQuery.value.toLowerCase())
                        })
                        return acc
                    }, {})
        })

        const dragRef = ref(null)


        const dragStart = (project) => {
           dragRef.value = project
        }
        const dragDrop = (category) => {
            let { id } = dragRef.value
            let project = dragRef.value

            project.status_id = category

            router.push({name: 'projects', params: { id: id, status: project.status_id, edit: 'edit', close: 'close'}})
        }


        watchEffect(() => {
            statusList.value.forEach(el => {
                if(el.status_name == 'press_cred'){
                    el.status_title = 'Press Cred'
                } else if(el.status_name == 'rfi') {
                    el.status_title = 'RFI'
                } else if(el.status_name == 'rfp') {
                    el.status_title = 'RFP'
                } else if (el.status_name == 'keynote') {
                    el.status_title = 'Brief/Keynote'
                } else if (el.status_name == 'sow') {
                    el.status_title = 'SOW'
                }
            });

        })

        return { statusList, getProjectItems, searchQuery, dragStart, dragDrop, projectCol, projectList, state, filteredProjects, filterState, filterUsers, filteredProjectCol }


    },

    methods: {
        nextStep: function(name, title) {
            console.log(name)
            this.$router.push(`/new-project/${name}/${title}`)
        },
    },
}
</script>