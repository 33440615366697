export default function getWeekNumber() {

const getWeekNo = (dDate) => {
    dDate = (typeof dDate === 'undefined') ? new Date() : dDate;
    var year = dDate.getFullYear();
    var month = dDate.getMonth() + 1;
    var day = dDate.getDate();

    var a = (month < 3) ? year - 1 : year;
    var b = parseInt(a / 4) - parseInt(a / 100) + parseInt(a / 400);
    var c = parseInt((a - 1) / 4) - parseInt((a - 1) / 100) + parseInt((a - 1) / 400);
    var s = b - c;
    var e = (month < 3) ? 0 : s + 1;
    var f = (month < 3) ? day - 1 + (31 * month - 1) : day + parseInt(153 * (month - 3) + 2) / 5 + 58 + s;
    var g = (a + b) % 7;
    var d = (f + g - e) % 7;
    var n = f + 3 - d;
    var weekno;
    switch (n) {
        case (n < 0):
            weekno = 53 - parseInt((g - s) / 5);
            break;
        case (n > (364 + s)):
            weekno = 1;
            break;
        default:
            weekno = parseInt(n / 7) + 1;
    }
    return weekno;
}

return { getWeekNo }
}