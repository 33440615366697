<template>
    <main>
        <Header msg="Project Status Graph" />
        <router-link to="/home" class="go-back">
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="m1 0 5 5L11 0" stroke="#0F2D4F" fill="none"/>
            </svg>
            Go back
        </router-link>
    <Chart />
    </main>
</template>
<script>
import Chart from '@/components/Chart.vue'
import Header from '@/components/Header.vue'
import useState from '@/use/useState'
import { useRouter } from "vue-router";


export default {
    name: 'Bar Chart',
    setup(){
        const state = useState()
        const router = useRouter();


        if(!state.value.loggedIn) {
        router.push('/')
        }
    },
    components: { Chart, Header }
}
</script>