<template>
  <tr>
    <td>
      <form class="hidden-form" @submit.prevent="update()" :id="`budget-${data.id}`" />
      {{ data.budgetRange }}
    </td>
    <td>
      <input type="number" min="0" :form="`budget-${data.id}`" class="time" :id="`time-${data.id}`" v-model="time" @blur="forceZero()" />
    </td>
    <td class="actions">
      <div><button :form="`budget-${data.id}`">Update</button></div>
    </td>
  </tr>
</template>

<script>
import { ref } from 'vue'

import useAPI from '@/use/useAPI'
import { loadBudgetTimeData } from '@/use/useBudgetToTime';
const { putData } = useAPI();

export default {
  name: 'Project Share',
  props: ['data'],

  setup(props) {
    const time = ref(props.data.weeks)

    function forceZero() {
      if (time.value == '') {
        time.value = 0
      }
    }

    const update = async () => {
      try {
        let response;

        let payload = {
          "id": props.data.id,
          "name": props.data.name,
          "budget": props.data.budget,
          "weeks": time.value
        };

        response = await putData(`/Budget_to_time/${props.data.id}`, payload);

        if (response.status >= 200 && response.status < 300) {
          loadBudgetTimeData();
        } else {
          throw response.status;
        }

        alert(`Budget ${props.data.budgetRange}'s time value has been updated from ${props.data.weeks} to ${time.value}!`)
      } catch (error) {
        alert(`There was an error! ${error}`);
      }

      return;
    }

    return {
      forceZero, update, data: props.data, time
    }
  }
}

</script>