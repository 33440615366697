// ------------------------------------------------
//
// Use Heat List
// Returns a global shared reference to the project type list
//
// ------------------------------------------------


import { ref } from 'vue'


const listRef = ref([])


//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
    return function(value) { name.value = value }
}


//
//  Load Heat list
//
import useAPI from '@/use/useAPI'
const { getData } = useAPI();
loadHeatData()
function loadHeatData(){
    // fetch("http://localhost:3000/HeatTest/")
    getData("/heat/")
        .then(setRef(listRef))
        .catch((err) => console.log(err.message));
}

// export default function getdata(access_token){

//     // we define a header when using a secured API
//     var headers = new Headers();
//     // uses the accesstoken we requested
//     var bearer = "bearer " + access_token;
//     headers.append("Authorization", bearer);
//     var options = {
//          method: "GET",
//          headers: headers
//     };

//     var Endpoint = "https://frankly-forecast-be-dev.azurewebsites.net/api/heat";

//     fetch(Endpoint, options)
//     .then(response => response.json())
//     .then(data => console.log(data));
// }




export default function useHeatList(){
    //
    //  Return shared Heat list
    //
    return listRef
}