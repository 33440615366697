<template>
    <main>
      <Header msg="Forecast"/>  

      <Admin />

    </main>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Admin from '@/components/Admin.vue'

import useState from '@/use/useState'
import { useRouter } from "vue-router";


export default {
  name: 'AdminView',
  setup(){
    const state = useState()
    const router = useRouter();

    if(!state.value.loggedIn) {
      router.push('/')
    }
  },
  components: {
    Header,
    Admin
  },
}
</script>