<template>
    <h1 class="headline">{{ msg }} <span v-if="submsg">-</span> {{ submsg}} </h1>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String,
    submsg: String
  }
}
</script>


