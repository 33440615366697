<template>
    <article class="modal">
        <Header :msg="msg"/>
        <div class="wrapper">
            <h2>Are you sure you want to {{ msg }} this project?</h2>
            <div class="button-wrapper">
                <button class="decline red" @click="$emit('close-modal')">No</button>
                <a href="/home" @click="$emit('save-action')" class="accept button red">
                    Yes
                </a>
            </div>
        </div>
    </article>
</template>

<script>
import useState from '@/use/useState'

import Header from '@/components/Header.vue'

import { ref } from 'vue'

export default {
    name: 'Modal',
    components: { Header },
    setup() {
        const state = useState()

        const modal = ref(false)
        state.value.modal = modal

        const toggleModal = () => {
            console.log(modal.value)
            modal.value = !modal.value
        }

        return { state, toggleModal }
    },
    props: {
        msg: String,
    }
}
</script>