// ------------------------------------------------
//
// Use Graph List
// Returns a global shared reference to the project type list
//
// ------------------------------------------------


import { ref } from 'vue'



const listRef = ref([])


//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
    return function(value) { name.value = value }
}


//
//  Load graph list
//
import useAPI from '@/use/useAPI'
const { getData } = useAPI();
loadGraphData()
function loadGraphData(){
    // fetch("http://localhost:3000/graphTest/")
    getData("/graph/")
        .then(setRef(listRef))
        .catch((err) => console.log(err.message));
}




export default function useGraphList(){
    //
    //  Return shared graph list
    //
    return listRef
}