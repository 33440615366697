<template>
    <Header :msg="!state.status ? 'New Project' : `Add ${title}`"/>

    <button class="go-back" @click="previousStep()">
        <svg xmlns="http://www.w3.org/2000/svg">
            <path d="m1 0 5 5L11 0" fill="none"/>
        </svg>
        Go back
    </button>

    <article class="add-project-wrapper" :class="!state.status ? '' : 'selected'">
        <article class="add-project">

            <nav class="add-project-nav" :class="!state.status ? '' : 'selected'">
                <h3 @click="toggleStatusList()"
                :class="!hideStatusList ? 'active' : ''">
                    Project Status <span v-if="state.status"> – {{ title }} </span>
                </h3>
                <ul v-show="!hideStatusList">
                    <li v-for="item in statusList"
                        :key="item.status_name">
                        <router-link
                            :to="{
                                name: 'new-project',
                                params:{ id: item.status_name }}
                            "
                            @click="hideStatusList='true'"
                            >
                            {{ item.status_title }}
                        </router-link>
                    </li>
                </ul>
            </nav>

            <article class="add-project one-col" v-if="state.status == 'press_cred'">
                <FirstLevelProject />
            </article>
            <article class="add-project two-col" v-if="state.status == 'rfi'">
                <SecondLevelProject />
            </article>
            <article class="add-project two-col" v-if="state.status == 'rfp'">
                <SecondLevelProject />
            </article>
            <article class="add-project three-col" v-if="state.status == 'keynote'">
                <ThirdLevelProject />
            </article>
            <article class="add-project three-col" v-if="state.status == 'sow'">
                <FourthLevelProject />
            </article>
        </article>
    </article>

</template>

<script>
// Import components
import Header from '@/components/Header.vue'
import Modal from '@/components/Modal.vue'
import FirstLevelProject from '@/components/FirstLevelProject.vue'
import SecondLevelProject from '@/components/SecondLevelProject.vue'
import ThirdLevelProject from '@/components/ThirdLevelProject.vue'
import FourthLevelProject from '@/components/FourthLevelProject.vue'

// Import from Use folder
import useStatusList from '@/use/useStatusList.js'
import useState from '@/use/useState'

// Import vue things
import { ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'NewProject',
    props: {},
    components: { 
        Header, 
        Modal, 
        FirstLevelProject,
        SecondLevelProject,
        ThirdLevelProject,
        FourthLevelProject
    },

    setup() {

        const route = useRoute()
        const router = useRouter()
        const statusList = useStatusList()
        const state = useState()
        const title = ref('')


        const hideStatusList = ref(false)

        if(!state.value.loggedIn) {
        router.push('/')
        }

        watchEffect(()=> {
            const { id } = route.params
            state.value.status = id

            if(state.value.status) {
                hideStatusList.value = true
            } else {
                hideStatusList.value = false
            }

            if (state.value.status == 'press_cred'){
                title.value = "Press Cred"
            } else if (state.value.status == 'rfi'){
                title.value = "RFI"
            } else if (state.value.status == 'rfp') {
                title.value = "RFP"
            } else if (state.value.status == 'keynote') {
                title.value = "Brief/Keynote"
            } else if(state.value.status == 'sow') {
                title.value = "SOW"
            }

            // Assign title value to each object
            statusList.value.forEach(el => {
                if(el.status_name == 'press_cred'){
                    el.status_title = 'Press Cred'
                } else if(el.status_name == 'rfi') {
                    el.status_title = 'RFI'
                } else if(el.status_name == 'rfp') {
                    el.status_title = 'RFP'
                } else if (el.status_name == 'keynote') {
                    el.status_title = 'Brief/Keynote'
                } else if (el.status_name == 'sow') {
                    el.status_title = 'SOW'
                }
            });
        })

        // Toggle project status dropdown
        const toggleStatusList = () => {
            if (state.value.status !== "") {
                hideStatusList.value = !hideStatusList.value
            }
        }

        // Function to go back to only show navigation containing status list elements
        const previousStep = () => {
            state.value.status = null
            router.go(-1)
        }


        return {
            state,
            statusList,
            toggleStatusList,
            hideStatusList,
            previousStep,
            title
        }

    },
}
</script>