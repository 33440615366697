import { ref } from 'vue'

const compRef = ref({
    type: 'RecursorView',
    children: [
        {
            type: 'fieldset',
            attrs: {
                class: 'wrapper-1 project-item'
            },
            children: [
                {
                    type: 'input',
                    name: 'projectStatus',
                    attrs: {
                        disabled: 'true',
                        id: 'selected-status',
                    }
                },
                {
                type: 'InputText',
                data: {
                    label: 'client name',
                    name: 'clientName',
                    type: 'text'
                }
                },
                {
                type: 'InputText',
                data: {
                    label: 'project name',
                    name: 'projectName',
                    type: 'text'
                }
                },
                {
                type: 'InputSelect',
                data: {
                    label: 'project type',
                    class: 'select-label',
                    options: [
                    { label: 'Foo', value: 1 },
                    { label: 'Bar', value: 2 },
                    { label: 'Baz', value: 3 },
                    { label: 'Qux', value: 4 },
                    ]
                }
                },
                {
                type: 'InputText',
                data: {
                    label: 'contact name',
                    name: 'contactName',
                    type: 'text'
                }
                },
                {
                type: 'InputText',
                data: {
                    label: 'phone number',
                    name: 'contactPhone',
                    type: 'phone'
                }
                },
                {
                type: 'InputText',
                data: {
                    label: 'email',
                    name: 'contactEmail',
                    type: 'email'
                }
                },
            ]
        },
        {
        type: 'fieldset',
        attrs: {
            class: 'wrapper-2 project-item'
        },
        children: [
            {
            type: 'InputText',
            data: {
                label: 'budget'
            }
            },
            {
            type: 'InputText',
            data: {
                label: 'deadline'
            }
            },
            {
            type: 'InputText',
            data: {
                label: 'po number'
            }
            }
        ]
        },
        {
            type: 'fieldset',
            attrs: {
                class: 'wrapper-3 project-item',
            },
            children: [
                {
                    type: 'InputSelect',
                    data: {
                        label: 'Select Label',
                        value: null,
                        placeholder: 'Select something',
                        size: '5',
                        options: [
                        { label: 'Foo', value: 1 },
                        { label: 'Bar', value: 2 },
                        { label: 'Baz', value: 3 },
                        { label: 'Qux', value: 4 },
                        ]
                    }
                }
            ]
        },
    ]
  }) 

  export default function useCompRef() {
      return compRef
  }