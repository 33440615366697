import { ref } from 'vue'

const stateRef = ref({
    status: null,
    activeMonth: null,
    username: '',
    loggedIn: false,
    scope: '',
    adminAccess: false
})

export default function useState() {
    
    return stateRef
}