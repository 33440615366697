// ------------------------------------------------
//
// Use Budget to Time
// Returns a global shared reference to the budget/time matrix
//
// ------------------------------------------------

import { ref } from 'vue'
import useAPI from '@/use/useAPI'
const { getData, putData, postData } = useAPI();
import moment from 'moment'
import useFormat from '@/use/useFormat'

const listRef = ref([])

//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
  return function(value) { name.value = value }
}

export function loadBudgetTimeData(){
  getData("/Budget_to_time/")
  .then( data => {
    let lastVal = null;
    let numFormat = Intl.NumberFormat("da");
    let budgetRange = data.reverse().map( i => {
      if (lastVal != null) {
        i.budgetRange = `${numFormat.format(i.budget)} – ${numFormat.format(lastVal-1)}`
      } else {
        i.budgetRange = `${numFormat.format(i.budget)}+`
      }
      lastVal = i.budget;
      return i;
    }).reverse();

    return budgetRange;
  })
  .then(setRef(listRef))
  .catch((err) => console.log(err.message));
}

export default function useBudgetToTime() {
  return listRef;
}