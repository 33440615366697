<template>
    <button class="go-back" @click="$router.back()">
        <svg xmlns="http://www.w3.org/2000/svg">
            <path d="m1 0 5 5L11 0" fill="none"/>
        </svg>
        Go back
    </button>
</template>

<script>
export default {
    name: 'GoBack',
}
</script>