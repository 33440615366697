import AuthService from '../services/msal2-authService';

const authService = new AuthService();

async function logout() {
    try {
        await authService.logout()
        
    } catch (error) {
        console.log(error)
    }
}


export default function useLogout() {
    
    return logout()
}