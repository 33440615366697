<template>
    <article class="admin-page" v-if="state.adminAccess">
        <nav class="floating-nav">
            <ul>
                <li>
                    <a href="#project-type-wrapper" data-text="Project Type">Project Type</a>
                </li>
                <li>
                    <a href="#project-role-wrapper" data-text="Project Role">Project Role</a>
                </li>
                <li>
                    <a href="#project-share-wrapper" data-text="Project Share">Project Share</a>
                </li>
                <li>
                    <a href="#multiplier-wrapper" data-text="Multiplier Values">Multiplier Values</a>
                </li>
                <li>
                    <a href="#budget-to-time" data-text="Budget to Time">Budget to Time</a>
                </li>
            </ul>
        </nav>
        <div>
            <section id="project-type-wrapper">
                <h2>Project Types</h2>
                <p class="info">A project type can only be deleted if no projects are assigned to it.</p>

                <table class="types">
                    <tr class="headers">
                        <th>Type Name</th>
                        <th>Actions</th>
                    </tr>
                    <template v-for="item in projectTypeList" :key="item.id">
                        <ProjectType :data="item" />
                    </template>
                    <ProjectType :data="{}" />
                </table>
            </section>

            <section id="project-role-wrapper">
                <h2>Project Roles</h2>
                <p class="info">A project role can only be deleted if no projects are assigned to it.</p>
                <table class="roles">
                    <tr class="headers">
                        <th>Role Name</th>
                        <th>Capacity</th>
                        <th>Distribution</th>
                        <th>Actions</th>
                    </tr>
                    <template v-for="role in projectRoleList" :key="role.id">
                        <ProjectRole :data="role" />
                    </template>
                    <ProjectRole :data="{}" />
                </table>
            </section>

            <section id="project-share-wrapper">
                <h2>Project Share</h2>

                <div class="wrapper">
                    <ProjectShare v-for="item in projectShareData" v-bind:key="item.type_id" :data="item"></ProjectShare>
                </div>
            </section>


            <section id="multiplier-wrapper">
                <h2>Change Multiplier Values</h2>
                <table class="types">
                    <tr class="headers">
                        <th>Status Name</th>
                        <th>Existing Client Multiplier</th>
                        <th>New Client Multiplier</th>
                        <th>Actions</th>
                    </tr>
                    <tr v-for="status in statusList" :key="status.id">
                        <td span="1">
                            <form class="hidden-form" @submit.prevent="updateMultiplierValues(status)" :id="`status-${status.id}`" />
                            {{ status.status_name }}
                        </td>
                        <td span="1">
                            <input type="number" name="multiplier_existing" v-model="status.multiplier_client_existing" step="0.05" min="0" :form="`status-${status.id}`">
                        </td>
                        <td span="1">
                            <input type="number" name="multiplier_new" v-model="status.multiplier_client_new" step="0.05" min="0" :form="`status-${status.id}`">
                        </td>
                        <td class="actions">
                            <div><button :form="`status-${status.id}`">Update</button></div>
                        </td>
                    </tr>
                </table>
            </section>

            <section id="budget-to-time">
                <h2>Budget to Time</h2>
                <table>
                    <tr class="headers">
                        <th>Budget</th>
                        <th>Weeks</th>
                        <th>Actions</th>
                    </tr>
                    <template v-for="item in budgetTime" :key="item.id">
                        <BudgetTime :data="item" />
                    </template>
                </table>
            </section>
        </div>
    </article>

    <article v-else>
        <h1>Looks like you're not authorized to see this page.</h1>
        <router-link to="/home">Go back</router-link>
    </article>
</template>
<script>
import useState from '@/use/useState'
import useProjectList, { addProjectTypeData, addProjectRoleData, updateMultiplier } from '@/use/useProjectList'
import useStatusList, { loadStatusData } from '@/use/useStatusList'
import useProjectTypeList, { loadProjectTypeData } from '@/use/useProjectTypeList'
import useProjectRoleList, { loadFullProjectRoleData } from '@/use/useProjectRoleList'
import useProjectTypeRoleList, { saveProjectTypeRoleData, loadProjectTypeRoleData } from '@/use/useProjectTypeRole'
import useProjectWorkload from '@/use/useProjectWorkload'
import useBudgetToTime, {loadBudgetTimeData} from '@/use/useBudgetToTime'

import { ref, watchEffect, reactive, computed, watch } from 'vue'

import useAPI from '@/use/useAPI'
const { putData, deleteData, postData } = useAPI();

import ProjectShare from './ProjectShare.vue'
import ProjectRole from './ProjectRole.vue'
import ProjectType from './ProjectType.vue'
import BudgetTime from './BudgetTime.vue'

export default {
    name: 'Admin',
    created() {
        loadProjectTypeData();
        loadProjectTypeRoleData();
        loadFullProjectRoleData();
        loadStatusData();
        loadBudgetTimeData();
    },
    setup() {
        const state = useState()
        const projectList = useProjectList()
        const statusList = useStatusList()
        const projectTypeList = useProjectTypeList();
        const projectRoleList = useProjectRoleList()
        const projectTypeRole = useProjectTypeRoleList()
        const projectWorkload = useProjectWorkload();
        const budgetTime = useBudgetToTime();

        const showProjectTypeModal = ref(false)
        const showProjectRoleModal = ref(false)
        const projectType = ref(null)
        const projectRole = ref(null)

        const modelTypeRef = ref({
            type_name: ''
        })
        const modelRoleRef = ref({
            role_name: ''
        })
        const modelTimeDisRef = ref({
            distribution: ''
        })

        state.value.status = 'admin'

        /* CREATING PROJECT SHARE DATA STRUCTURE
            {
                [type_id]: {
                    type_id: 1,
                    type_name: "App",
                    roles: {
                        [role_id]: {
                            role_id: 1,
                            role_name: "Project Management",
                            project_share: 0,
                            project_share_id: 1
                        }
                    }
                }
            }

            uses projectTypeList and projectRoleList and projectTypeRole
        */

        const projectShareData = ref({});

        watch([projectTypeList, projectRoleList, projectTypeRole], ([$projectTypeList, $projectRoleList, $projectTypeRole]) => {
            // don't make dict if not all values are ready
            if ($projectTypeList.length == 0 || $projectRoleList?.length == 0 || $projectTypeRole?.length == 0) {
                return;
            }

            let dict = {};

            for (const type of $projectTypeList) {
                dict[type.id] = {
                    type_id: type.id,
                    type_name: type.type_name,
                    roles: {},
                }

                for (const role of $projectRoleList) {
                    // get existing project_share data, if undefined, set to 0
                    const project_share = $projectTypeRole.find(item =>
                        item.type_id == type.id && item.role_id == role.id
                    )

                    dict[type.id].roles[role.id] = {
                        role_id: role.id,
                        role_name: role.role_name,
                        project_share: project_share?.project_share ?? 0,
                        project_share_id: project_share?.id
                    }
                }
            }

            projectShareData.value = dict;
        })



        const saveProjectType = async () => {
            let response;
            try {
                response = await postData(`/project_type`, modelTypeRef.value)
                loadProjectTypeData();
                alert(`${modelTypeRef.value.type_name} has been created!`);
            } catch (error) {
                console.log(error)
            }
        }

        const updateMultiplierValues = async (status) => {
            console.log(status);

            const { id, status_name } = status
            let response;
            try {
                response = await putData(`/project_status/${id}`, status);

                if (response.status >= 200 && response.status < 300) {
                    alert(`${status_name}'s details were successfully updated`);
                } else {
                    console.log(response.status)
                }
            } catch (error) {
                console.log(error)
            }
        }

        const updateTypeNames = async (type) => {
            const { id, type_name } = type
            let response;
            try {
                response = await putData(`/project_type/${id}`, {
                    id, type_name
                });
                console.log(response);
                if (response.status >= 200 && response.status < 300) {
                    // window.location.reload()
                    loadProjectTypeData();
                    alert(`${type_name} has been updated!`)
                } else {
                    console.log(response.status)
                }
            } catch (error) {
                console.log(error)
            }
        }



        // Update and Delete Project_Type
        const showWarningProjectType = (type) => {
            projectType.value = type
            showProjectTypeModal.value = true

        }
        const deleteProjectType = async () => {
            const { id, type_name } = projectType.value
            // deleteData(`/project_type/${id}`)
            showProjectTypeModal.value = false
            let response;
            try {
                response = await deleteData(`/project_type/${id}`)

                if (response.status >= 200 && response.status < 300) {
                    loadProjectTypeData();
                    alert(`${type_name} has been deleted!`)
                } else {
                    console.log(response.status)
                }
            } catch (error) {
                console.log(error)
            }
        }

        // Update and Delete Project_Role
        const showWarningProjectRole = (role) => {
            projectRole.value = role
            showProjectRoleModal.value = true

        }
        const deleteProjectRole = () => {
            const { id } = projectRole.value
            console.log(id)
            deleteData(`/project_role/${id}`);
        }

        // Function to check if a project type can be deleted or not
        const filterProjectTypes = () => {
            projectTypeList.value.forEach(type => {
                type.hasProject = projectList.value.some(project => type.id == project.type_id);
            });
        }

        // Checks if role is being used in any workload (more than 0 in ANY project);
        const filterProjectRoles = () => {
            projectRoleList.value.forEach(role => {
                role.hasProject = projectWorkload.value.some(workload => role.id == workload.role_id && workload.workload > 0
                );
            });
        }

        // ------------------------------
        // FUNCTIONS TO PROJECT_TYPE_ROLE
        // ------------------------------
        watchEffect(() => {
            // Insert role and type name to project_role_type
            // so it can be displayed on the interface
            projectTypeList.value.forEach(type => {
                projectTypeRole.value.filter(itm => {
                    if (itm.type_id == type.id) {
                        itm.type_name = type.type_name
                    }
                })
            })
            projectRoleList.value.forEach(role => {
                projectTypeRole.value.filter(itm => {
                    if (itm.role_id == role.id) {
                        itm.role_name = role.role_name
                    }
                })
            })

            // Call filter on project_type to check if it can be deleted or not
            filterProjectTypes();
            filterProjectRoles();
        })

        return {
            modelTypeRef,
            modelRoleRef,
            projectList,
            saveProjectType,
            statusList,
            updateMultiplierValues,
            projectTypeList,
            updateTypeNames,
            showProjectTypeModal,
            deleteProjectType,
            projectType,
            showWarningProjectType,
            showProjectRoleModal,
            projectRole,
            showWarningProjectRole,
            deleteProjectRole,
            projectRoleList,
            filterProjectTypes,
            state,
            projectTypeRole,
            modelTimeDisRef,
            projectShareData,
            budgetTime
        }
    },
    components: {
        ProjectShare,
        ProjectRole,
        ProjectType,
        BudgetTime
    }
}
</script>