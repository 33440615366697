<template>
    <main>
        <NewProject />
    </main>
</template>

<script>
import NewProject from '@/components/NewProject.vue'
import useState from '@/use/useState'
import { useRouter } from "vue-router";


export default {
  name: 'NewProjectView',
  setup(){
    const state = useState()
    const router = useRouter();

    if(!state.value.loggedIn) {
      router.push('/')
    }
  },
  components: {
      NewProject,
  },
}
</script>
