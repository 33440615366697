<template>
    <main>
      <Header msg="Forecast"/>
      <article class="login-form">
        <MSAL2 />
      </article>
    </main>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import MSAL2 from '@/components/MSAL2.vue'

import useState from '@/use/useState'
import { watchEffect } from 'vue'

export default {
  name: 'LoginView',
  setup(){
    const state = useState()

    state.value.status = 'login'
  },
  components: {
    Header,
    MSAL2,
  },
}
</script>