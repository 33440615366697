import AuthService from "../services/msal2-authService";
import useState from '@/use/useState'

const authService = new AuthService();

export default function useAPI() {
  const state = useState(

  )
  const root = "https://frankly-forecast-be-dev.azurewebsites.net/api";

  async function getData(endpoint) {
    // if(!authService.accessToken) {
    //   return console.log('not logged in')
    // } else {
    //   // get token
    //   await authService.getAccessToken();
    //   var bearer = "bearer " + authService.accessToken;

    //   // we define a header when using a secured API
    //   var headers = new Headers();
    //   headers.append("Authorization", bearer);

    //   var options = {
    //     method: "GET",
    //     headers: headers,
    //   };

    //   const response = await fetch(root + endpoint, options);
    //   const data = await response.json();
    //   return data;
    // }
    // get token
    await authService.getAccessToken();
    var bearer = "bearer " + authService.accessToken;

    // Fetch data if a user is logged in
    if(!authService.accessToken) {
      console.log('not logged in')
    } else {
      // we define a header when using a secured API
      var headers = new Headers();
      headers.append("Authorization", bearer);

      var options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(root + endpoint, options);
      const data = await response.json();
      return data;
    }

  }

  async function putData(endpoint, data) {
    // get token
    await authService.getAccessToken();
    var bearer = "bearer " + authService.accessToken;

    // we define a header when using a secured API
    var headers = new Headers();
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    var options = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    };

    const response = await fetch(root + endpoint, options);
    if (response.status >= 200 && response.status < 3004) {
      return response;
    }
    return await response.json();
  }

  async function postData(endpoint, data) {
    // get token
    await authService.getAccessToken();
    var bearer = "bearer " + authService.accessToken;

    // we define a header when using a secured API
    var headers = new Headers();
    headers.append("Authorization", bearer);
    headers.append("Content-Type", "application/json");

    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };

    const response = await fetch(root + endpoint, options);
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    return await response.json();
  }

  async function deleteData(endpoint) {
    // get token
    await authService.getAccessToken();
    var bearer = "bearer " + authService.accessToken;

    // we define a header when using a secured API
    var headers = new Headers();
    headers.append("Authorization", bearer);

    var options = {
      method: "DELETE",
      headers: headers,
    };

    const response = await fetch(root + endpoint, options);
    return response;
  }

  return { getData, putData, postData, deleteData };
}
