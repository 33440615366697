// ------------------------------------------------
//
// Use ProjectTypeRole List
// Returns a global shared reference to the ProjectRole list
//
// ------------------------------------------------


import { ref } from 'vue'


const listRef = ref([])


//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
    return function(value) { name.value = value }
}


//
//  Load ProjectRole list
//
import useAPI from '@/use/useAPI'
const { getData, postData } = useAPI();
loadProjectTypeRoleData()
export function loadProjectTypeRoleData(){
    getData("/project_type_role/")
        .then(setRef(listRef))
        .catch((err) => console.log(err.message));
}

export function saveProjectTypeRoleData(modelTypeRoleRef){
    // return console.log(modelTypeRoleRef)
    postData(`/project_type_role`, modelTypeRoleRef)
}




export default function useProjectTypeRoleList(){
    //
    //  Return shared ProjectRole list
    //
    return listRef
}