<template>
  <article v-if="!state.loggedIn">
    <h1 class="dark-text">Hello!</h1>
    <h2>Login to your account</h2>
    <button @click.prevent="login">Login</button>
  </article>
  <article v-else>
    <h1 class="dark-text">Hello {{ state.username }}!</h1>
    <h2>You're already logged in</h2>
    <br>
    <router-link to="/home" class="button">Go to the homepage</router-link>

  </article>
</template>

<script>
import AuthService from '../services/msal2-authService';
import { useRoute, useRouter } from "vue-router";
import useState from '@/use/useState'
import useLogin from '@/use/useLogin'
import useLogout from '@/use/useLogout'



export default {
  name: 'MSAL2',
  setup(){
    const state = useState()    

    // Login function
    const login = async() => {
      useLogin()
    }

    // Logout function
    const logout = () => {
      useLogout()
    }
    

    return { 
      login,
      logout,
      state,
    }
  },
}
</script>