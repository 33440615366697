import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import styles from './styles/style.scss'
import AuthService from '@/services/msal2-authService'

const authService = new AuthService()
authService.getAccessToken()
authService.getLoggedInUser()

// console.log(authService.accessToken)
// console.log(authService.request.account)

createApp(App).use(router).mount('body')


