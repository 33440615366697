// ------------------------------------------------
//
// Use Status List
// Returns a global shared reference to the status list
//
// ------------------------------------------------


import { ref } from 'vue'


const listRef = ref([])


//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
    return function(value) { name.value = value }
}


//
//  Load status list
//
import useAPI from '@/use/useAPI'
const { getData } = useAPI();
loadStatusData()
export function loadStatusData(){
    getData("/project_status/")
        .then(setRef(listRef))
        .catch((err) => console.log(err.message));
}
// function loadStatusData(){
//     fetch("http://localhost:3000/project-status-list/")
//         .then((res) => res.json())
//         .then(setRef(listRef))
//         .catch((err) => console.log(err.message));
// }




export default function useStatusList(){
    //
    //  Return shared status list
    //
    return listRef
}