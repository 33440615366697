<template>
  <form @submit.prevent novalidate class="add-project" ref="form">
    <fieldset class="project-item">
      <input type="text" id="status" hidden v-model="modelRef.status_id" />

      <label for="clientName">Client Name</label>
      <input
        type="text"
        id="clientName"
        v-model="modelRef.client_name"
        @input="validate.client_name()"
        @blur="validate.client_name()"
      />

      <p class="error-message" v-if="errors.client_name">
        {{ errors.client_name }}
      </p>
    </fieldset>

    <fieldset class="button-wrapper">
      <FormButton
        :ctaMsg="'cancel'"
        type="button"
        @click="cancelNewProject()"
      />
      <FormButton :ctaMsg="'save'" type="submit" @click="submitForm()" />
    </fieldset>
  </form>
  <Modal msg="cancel" v-if="showModal" @close-modal="showModal = false" />

  <SuccessMessage v-if="showSuccess" />
</template>

<script>
// Import components
import FormButton from "@/components/FormButton.vue";
import Modal from "@/components/Modal.vue";
import SuccessMessage from "@/components/SuccessMessage.vue";

// Import from Use folder
import { loadProjectData } from "@/use/useProjectList";
import useStatusList from "@/use/useStatusList.js";
import useState from "@/use/useState";
import useFormValidation from "@/use/useFormValidation";

// Import vue things
import { ref, watchEffect } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";

import useAPI from "@/use/useAPI";
const { postData } = useAPI();
import useFormat from "@/use/useFormat";

export default {
  name: "NewProject",
  props: {},
  components: { FormButton, Modal, SuccessMessage },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const statusList = useStatusList();
    const state = useState();

    const form = ref(null);
    const statusId = ref(null);
    const title = ref("");
    const showModal = ref(false);
    const showSuccess = ref(false);

    const current = new Date();
    const date = moment(current).format();

    console.log(date);

    const { formatTimestampToDate, formatDateToTimestamp } = useFormat();

    watchEffect(() => {
      const { id } = route.params;
      state.value.status = id;

      // Find and set matching status_id for posting to the db
      statusList.value.forEach((el) => {
        if (state.value.status == el.status_name) {
          statusId.value = el.id;
        }
      });
    });

    const modelRef = ref({
      client_name: "",
      created_by: state.value.username,
      created_at: date,
      last_modified: date, // SHOULD BE NULL OR EMPTY
      project_name: "",
      project_deadline: null,
      project_budget: 0,
      contact_name: "",
      contact_phone: null,
      contact_email: "",
      existing_client: false,
      po_number: null,
      project_deleted: null,
      project_archived: null,
      type_id: null,
      status_id: statusId,
    });

    const cancelNewProject = () => {
      showModal.value = true;
    };

    // Validate input fields
    const {
      validateNameField,
      validateEmailField,
      validatePhoneField,
      validateNumberField,
      validateDateField,
      errors,
    } = useFormValidation();

    const validate = (() => {
      return {
        client_name: () =>
          validateNameField("client_name", modelRef.value.client_name),
        // project_name: () => validateNameField("project_name", modelRef.value.project_name),
        // type_id: () => validateNameField("type_id", modelRef.value.type_id),
        // contact_name: () => validateNameField("contact_name", modelRef.value.contact_name),
        // contact_email: () => validateEmailField("contact_email", modelRef.value.contact_email),
        // contact_phone: () => validatePhoneField("contact_phone", modelRef.value.contact_phone),
        // project_budget: () => validateNumberField("project_budget", modelRef.value.project_budget),
        // po_number: () => validateNumberField("po_number", modelRef.value.po_number),
        // project_deadline: () => validateDateField( "project_deadline", modelRef.value.project_deadline),
      };
    })();

    const validateAll = () => {
      // check for errors
      for (const key in validate) {
        if (Object.hasOwnProperty.call(validate, key)) {
          const cb = validate[key];
          cb();
        }
      }

      const hasError = Object.values(errors).some((error) => error !== "");

      console.log(Object.entries(errors))

      return hasError;
    };

    const submitForm = async () => {
      // Post project data to database - projects table
      let response;

      let { id } = modelRef.value;

      if (validateAll()) return;

      try {
        if (!id) {
          response = await postData("/projects", modelRef.value);
          showSuccess.value = true;
          setTimeout(() => {
            showSuccess.value = false;
            loadProjectData();
            router.push("/home");
          }, 1500);
        }

        let data = response.data;
        data.project_deadline = formatTimestampToDate(data.project_deadline);
        modelRef.value = data;

        console.log(response.data);
        console.log(data)
      } catch (error) {}
    };

    return {
      state,
      statusList,
      modelRef,
      title,
      showModal,
      statusId,
      submitForm,
      cancelNewProject,
      errors,
      validate,
      form,
      showSuccess,
    };
  },
};
</script>
