// ------------------------------------------------
//
// Use Product List
// Returns a global shared reference to the project list
//
// ------------------------------------------------


import { ref } from 'vue'
import useAPI from '@/use/useAPI'
const { getData, putData, postData } = useAPI();
import moment from 'moment'
import useFormat from '@/use/useFormat'



const listRef = ref([])


//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
    return function(value) { name.value = value }
}


//
//  Load project list
//

loadProjectData()
// function loadProjectData(){

//     fetch("http://localhost:3000/project-list/")
//     .then((res) => res.json())
//     .then(setRef(listRef))
//     .catch((err) => console.log(err.message));
// }
export function loadProjectData(){
    getData("/projects")
    .then(setRef(listRef))
    .catch((err) => console.log(err.message));
}


export function saveProjectData(project){
    let { id } = project
    if(!id) {
        console.log('Saving all projects', listRef.value)
    }
    else {
        console.log('Saving project', id)
        const { formatDateToTimestamp } = useFormat();
        let deadline = project.project_deadline ? formatDateToTimestamp(project.project_deadline) : null;
        const data = { ...project, project_deadline: deadline}
        putData(`/projects/${id}`, data)

    }
}

export function archiveProjectData(project){

    const current = new Date()
    const date = moment(current).format()

    let { id } = project
    project.project_archived = date
    project.project_deleted = date

    // return console.log(project)

    if(!id) {
        console.log('Saving all projects', listRef.value)
    }
    else {
        console.log('Saving project', id)
        const { formatDateToTimestamp } = useFormat();
        let deadline = project.project_deadline ? formatDateToTimestamp(project.project_deadline) : null;
        const data = { ...project, project_deadline: deadline}
        putData(`/projects/${id}`, data)
    }
}

export function addProjectTypeData(modelTypeRef){
    postData(`/project_type`, modelTypeRef)
}
export function addProjectRoleData(modelRoleRef){
    postData(`/project_role`, modelRoleRef)
}
export function updateMultiplier(status){
    console.log(status)
    // postData(`/project_role`, modelRoleRef)
}


export default function useProjectList(){
    //
    //  Return shared project list
    //
    return listRef
}