<template>
  <article class="barchart">
    <div class="buttons-wrapper">
      <button class="filter-bar-charts">My Projects</button>
      <fieldset class="dropdown" v-if="removeDuplicates().length > 1">
        <button @click="toggleFilters()" class="filter-by-year">
          Filter by year
        </button>
        <ul v-show="!hidden">
          <li class="filter-button" v-for="year in removeDuplicates()" @click="filterButtons(year)" :key="year">
            {{ year }}
          </li>
        </ul>
      </fieldset>
      <Toggle @toggle="sortByQty = !sortByQty" idName="viewToggle" labelOff="€" labelOn="Qty" :toggled="sortByQty" />
    </div>
    <article class="wrapper">
      <div class="labels" :data-label="sortByQty ? 'Qty' : '€uros'">
        <div class="label" v-for="x in segmentArr" :key="x">
          {{ x }}
        </div>
      </div>
      <div class="chart-container allow-scrollbar" :data-year="year" v-for="year in removeDuplicates()" :key="year" :class="year" v-show="active == year">
        <h4 class="year">{{ year }}</h4>
        <div class="graph-wrapper" v-for="month in months" :key="month.name" :id="month.id" ref="graphWrappersRefs">
          <h3 :class="month.id == currentMonth + 1 ? 'active' : ''" :id="month.id">{{ month.name }}</h3>

          <div v-for="x in graphList" :key="x.Month">
            <div v-if="x.Year == year">
              <div v-if="x.Month == month.id">
                <svg width="100" height="300">
                  <g>
                    <g>
                      <rect v-for="el in x.Status_list" :key="el.status_name" class="bar" :x="
                        20 *
                        projectTags.findIndex((tag) => tag === el.status_name)
                      " width="20" y="0" :height="
  scaleHeight(sortByQty ? el.Quantity : el.Value)
" :fill="fillColor[el.status_name]"></rect>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </article>

</template>
<script>
import { ref, watchEffect, computed, onMounted, onUpdated, nextTick, watch } from "vue";
import useGraphList from "@/use/useGraphList";
import useStatusList from "@/use/useStatusList";
import useState from '@/use/useState'

import Toggle from "@/components/Toggle.vue";


export default {
  name: "Chart",
  components: { Toggle },
  setup() {
    const graphList = useGraphList();
    const statusList = useStatusList();
    const state = useState()
    const graphYears = ref([]);
    const date = new Date()
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth()
    const active = ref(currentYear);
    const hidden = ref(true);
    const sortByQty = ref(false);
    const getStatusValues = ref([]);
    const segmentArr = ref([]);
    const maxValue = ref(0);
    const roundValue = ref(0);
    const pre = ref(null)
    const activeRef = ref(null)
    const graphWrappersRefs = ref([]);
    const roundTo = ref(0);

    const months = [
      { name: "january", id: 1 },
      { name: "february", id: 2 },
      { name: "march", id: 3 },
      { name: "april", id: 4 },
      { name: "may", id: 5 },
      { name: "june", id: 6 },
      { name: "july", id: 7 },
      { name: "august", id: 8 },
      { name: "september", id: 9 },
      { name: "october", id: 10 },
      { name: "november", id: 11 },
      { name: "december", id: 12 },
    ];

    const valueArr = [];
    const qtyArr = [];

    watchEffect(() => {
      valueArr.splice(0, valueArr.length); //clear array
      qtyArr.splice(0, qtyArr.length); //clear array

      graphList.value.forEach((el) => {
        const { Year, id } = el;
        graphYears.value.push(Year);

        el.Status_list.forEach((itm) => {
          const { Status_id } = itm;
          getStatusValues.value = statusList.value.filter((el, index) => {
            if (el.id == Status_id) {
              itm.status_name = el.status_name;
            }
          });

          if (Year == active.value) {
            valueArr.push(itm.Value);
            qtyArr.push(itm.Quantity);
          }
        });
      });

      maxValue.value = sortByQty.value ? Math.max(...qtyArr) : Math.max(...valueArr);

      roundTo.value = (() => {
        let x = maxValue.value;
        let round;

        if (x <= 5) {
          round = 1;
        } else if (x <= 20) {
          round = 5;
        } else if (x <= 50) {
          round = 10;
        } else if (x <= 100) {
          round = 25;
        } else if (x <= 250) {
          round = 50;
        } else if (x <= 500) {
          round = 100;
        } else if (x <= 5_000) {
          round = 1_000;
        } else if (x <= 30_000) {
          round = 5_000;
        } else if (x <= 50_000) {
          round = 10_000;
        } else if (x <= 100_000) {
          round = 25_000;
        } else if (x <= 300_000) {
          round = 50_000;
        } else if (x <= 500_000) {
          round = 100_000;
        } else if (x <= 1_000_000) {
          round = 250_000;
        }

        return round;
      })();

      roundValue.value = Math.ceil(maxValue.value / roundTo.value) * roundTo.value;

      const segmentCount = maxValue.value / roundTo.value + 1;
      const segmentValue = maxValue.value / (segmentCount - 1);
      segmentArr.value.splice(0, segmentArr.value.length); //clear array
      for (let i = 0; i < segmentCount; i++) {
        segmentArr.value.push(segmentValue * i);
      }


    });

    watchEffect(() => {
      if (graphWrappersRefs.value.length) {
        const elm = graphWrappersRefs.value[currentMonth];
        elm.scrollIntoView({ inline: "center" });
        elm.setAttribute('data-scrollTo', 'true');
        setTimeout(() => {
          elm.removeAttribute('data-scrollTo');
        }, 1000);
      }
    })

    const scaleHeight = (inputVal) => {
      const valueMax = roundValue.value;
      const visualMax = 300;
      const scale = valueMax / visualMax;

      return inputVal / scale;
    };

    const sortedYears = computed(() => {
      graphYears.value = graphYears.value.sort((a, b) => {
        return a - b;
      });
    });

    const removeDuplicates = () => {
      return graphYears.value.filter(
        (item, index) => graphYears.value.indexOf(item) === index
      );
    };

    const filterButtons = (year) => {
      const containers = document.querySelectorAll(".chart-container");
      containers.forEach((container) => {
        const attr = container.getAttribute("data-year");
        if (year == attr) {
          active.value = year;
        }
      });
    };

    // Toggle filter by year buttons
    const close = (e) => {
      const filterBtn = document.querySelector(".filter-by-year");
      if (e.target != filterBtn) {
        hidden.value = true;
      }
    };
    document.addEventListener("click", close);

    const toggleFilters = () => {
      hidden.value = !hidden.value;
    };

    // get svg color
    const fillColor = {
      press_cred: "#f17268",
      rfi: "#8682bc",
      rfp: "#e4d986",
      keynote: "#82cee6",
      sow: "#002e52",
    };

    const projectTags = Object.keys(fillColor);


    return {
      months,
      graphList,
      graphYears,
      removeDuplicates,
      filterButtons,
      currentYear,
      active,
      hidden,
      toggleFilters,
      sortedYears,
      sortByQty,
      getStatusValues,
      scaleHeight,
      segmentArr,
      fillColor,
      projectTags,
      currentMonth,
      pre,
      activeRef,
      state,
      graphWrappersRefs
    }
  }
};
</script>
