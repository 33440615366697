// ------------------------------------------------
//
// Use Product List
// Returns a global shared reference to the project list 
// 
// ------------------------------------------------


import { ref, computed } from 'vue'
import useStatusList from './useStatusList'
import useProjectList from './useProjectList'




const colRef = ref([])


const statusList = useStatusList()
const projectList = useProjectList()


const compRef = computed(()=>{
    // if(
    //     !statusList.value.length
    //     || !projectList.value.length
    // ) return { }
   

    
   return statusList.value.reduce((acc, itm, idx, arr) => {
        const { id } = itm
        acc[id] = projectList.value.filter(itm => itm.status_id == id)
        return acc
    }, {})
                   

    
})





export default function useProjectCollection(){
    // 
    //  Return shared project list
    //
    return compRef
}