// ------------------------------------------------
//
// Use ProjectWorkload List
// Returns a global shared reference to the ProjectRole list
//
// ------------------------------------------------


import { ref } from 'vue'


const listRef = ref([])


//
//  Curried set ref helper to be used with promises/fetch
//
function setRef(name) {
    return function(value) { name.value = value }
}


//
//  Load ProjectWorkload list
//
import useAPI from '@/use/useAPI'
const { getData } = useAPI();
loadProjectWorkloadData()
function loadProjectWorkloadData(){
    getData("/project_workload/")
        .then(setRef(listRef))
        .catch((err) => console.log(err.message));
}



export default function useProjectWorkload(){
    //
    //  Return shared ProjectWorkload list
    //
    return listRef
}

