<template>
  <main>
    <h1>Dev</h1>
    <label for="number">Insert number</label>
    <input type="number" id="number" v-model="inputNumber" @keyup="updateNumberInput(inputNumber)">
    <span>{{ value }}</span>

    {{ inputNumber }}

  </main>
</template>


<script>

// Import components
import Header from '@/components/Header.vue'
import RecursorComp from '@/components/RecursorComp.vue'
import FormButton from '@/components/FormButton.vue'
import Modal from '@/components/Modal.vue'
import FirstLevelProject from '@/components/FirstLevelProject.vue'


// Import from Use folder
import useStatusList from '@/use/useStatusList.js'
import useCompRef from '@/use/useCompRef'
import useState from '@/use/useState'
import useProjectTypeList from '@/use/useProjectTypeList'
import useProjectRoleList from '@/use/useProjectRoleList'
import useFormValidation from '@/use/useFormValidation'
import useSubmitButtonState from '@/use/useSubmitButtonState'

// Import vue things
import { ref, watchEffect, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'

import useFormat from '@/use/useFormat'


export default {
    name: '',
    props: {},
    components: {
    },
    setup() {
        const compRef = useCompRef()
        const route = useRoute()
        const router = useRouter()
        const statusList = useStatusList()
        const state = useState()

        const hideStatusList = ref(false)
        const statusId = ref(null)
        const title =  ref('')
        const showModal = ref(false)

        const current = new Date()
        const date = moment(current).format()

        const inputNumber = ref('')
        const value = ref('')

        

      watchEffect(()=> {
            const { id } = route.params
            state.value.status = id

            if(state.value.status) {
                hideStatusList.value = true
            } else {
                hideStatusList.value = false
            }
        })

        const updateNumberInput = (elm) => {
          let str = elm.toString().split('.')
          str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          value.value = str.join('.')

          return value.value
        }


     return {
       state, updateNumberInput, inputNumber, value
     }
    },
}
</script>
