<template>
    <div class="show-success">
        <div>
            <h2>Project saved</h2>
            <div class="checkmark draw"></div>
        </div>
    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>